import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import moment from "jalali-moment";
import {
  Body,
  Container,
  Header,
  TitleHead,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Lable,
  Textarea,
  DivRow,
  ListItems,
  DivList,
  DivItems,
  Item,
  Img,
  DivDate,
  DivTitleStatus,
  DivImgStatus,
} from "./Element";
import icon from "../../../Images/headerIcons/status.svg";
import { useTranslation } from "react-i18next";

const Status = ({
  showModalStatus,
  handleCloseModal,
  handleOpenModal,
  itemDetails,
}) => {
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [statuses, setStatuses] = useState();
  const [statusId, setStatusId] = useState();
  const [status, setStatus] = useState();
  const [listStatus, setListStatus] = useState();

  const { t } = useTranslation();
  useEffect(() => {
    setShowModal(showModalStatus);
    console.log(itemDetails);
  }, [showModalStatus, itemDetails]);

  const CloseModal = () => {
    handleCloseModal("modalStatus");
    setErrors({});
  };
  const getTime = (date) => {
    date = date.split(" ");
    return date[3];
  };
  const getCalendar = (date) => {
    date = date.split(" ");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead color="var(--orange)">
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("تاریخچه وضعیت فاکتور")}</TitleHead>
          </Header>
          <Body>
            <DivItems>
              <Lable>تاریخچه وضعیت ها</Lable>
              <ListItems>
                <DivList>
                  {itemDetails?.statuses?.map((status, index) => (
                    <Item active={index === 0}>
                      <DivTitleStatus>
                        <DivImgStatus color={status?.color}>
                          <Img src={icon} />
                        </DivImgStatus>
                        {status?.title}
                      </DivTitleStatus>
                      <DivDate>
                        {getTime(status?.jalali_created_at) +
                          " | " +
                          getCalendar(status?.jalali_created_at)}
                      </DivDate>
                    </Item>
                  ))}
                </DivList>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Status;
