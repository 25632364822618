import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import {
  Container,
  Item,
  Card,
  Btn,
  Icon,
  DetailItem,
  Title,
  BtnAdd,
  IconBtnAdd,
  CoverImg,
  Img,
  CoverIcon,
} from "./Element";
import card from "../../Images/cards/deliveryCopanies.svg";
import card_ltr from "../../Images/cards/deliveryCopanies_ltr.svg";
import setting from "../../Images/setting.svg";
import icon from "../../Images/addIcon.svg";
import linesDelivery from "../../Images/linesDelivery.svg";
import defaultImg from "../../Images/headerIcons/deliveryCompanies.svg";

import { useEffect, useState } from "react";

const List = ({ data, handleOpenModal, handleCloseModal }) => {
  const [items, setItems] = useState([]);
  const currentLanguageCode = cookies.get("i18next");
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <>
      <Container>
        {items.map((item) => (
          <Item key={item.id}>
            <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
            <DetailItem>
              <CoverImg>
                <Img src={item.logo ? item.logo : defaultImg} />
              </CoverImg>
              <Title>{item.title}</Title>
            </DetailItem>
            <CoverIcon>
              <Icon src={linesDelivery} />
            </CoverIcon>
            <Btn onClick={() => handleOpenModal("modalOperations", item)}>
              <Icon src={setting} />
            </Btn>
          </Item>
        ))}
        <BtnAdd onClick={() => handleOpenModal("modalAdd")}>
          <IconBtnAdd src={icon} />
          {t("add delivery company")}
        </BtnAdd>
      </Container>
    </>
  );
};

export default List;
