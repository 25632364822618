import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Lable,
  DivToggleButton,
  ToggleButton,
  Helper,
  Price,
} from "./Element";
import icon from "../../../Images/acceptIcon.svg";
import { useTranslation } from "react-i18next";

const Accept = ({
  showModalAccept,
  handleAddNew,
  handleCloseModal,
  handleAcceptRequest,
  itemDetails,
}) => {
  const [data, setData] = useState({
    price: "",
    confirmed_price_type: "percent",
    confirmed_price_percent: "",
    weight: "",
    description: "",
    register_at: "",
    confirmed_description: "",
    confirmed_price: 0,
    status: 1,
  });
  const [showModal, setShowModal] = useState(false);
  const [distance_priceWithCommas, setDistance_priceWithCommas] =
    useState(null);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalAccept);
    if (showModalAccept) {
      setData({
        ...data,
        price: itemDetails?.price,
        weight: itemDetails?.weight,
        description: itemDetails?.description,
        register_at: itemDetails?.register_at,
        confirmed_price: itemDetails?.price,
        discount_type: itemDetails?.discount_type,
        discount_value: itemDetails?.discount_value.toString(),
        discount_start_at: itemDetails?.discount_start_at,
        discount_end_at: itemDetails?.discount_end_at,
      });
    }
  }, [showModalAccept, itemDetails]);

  const schema = Joi.object({
    price: [Joi.number().integer().required(), Joi.allow(null)],
    confirmed_price_type: Joi.string().required(),
    confirmed_price_percent: Joi.number().integer().required(),
    weight: [Joi.number().integer().required(), Joi.allow(null)],
    description: [Joi.string().optional(), Joi.allow("")],
    register_at: Joi.string().required(),
    confirmed_description: [Joi.string().required(), Joi.allow(null)],
    discount_type: Joi.string().optional(),
    discount_value: Joi.string().optional(),
    discount_start_at: Joi.string().optional(),
    discount_end_at: Joi.string().optional(),
    confirmed_price: [Joi.number().integer().required(), Joi.allow(null)],
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const handleNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const CloseModal = () => {
    handleCloseModal("modalAccept");
    setData({
      price: "",
      confirmed_price_type: "percent",
      confirmed_price_percent: "",
      weight: "",
      description: "",
      register_at: "",
      confirmed_description: "",
      confirmed_price: 0,
      status: 1,
    });
    setErrors({});
  };
  const handleToggleButton = () => {
    if (data.confirmed_price_type === "percent") {
      setData({
        ...data,
        confirmed_price_type: "regular",
        confirmed_price_percent: 0,
        confirmed_price: itemDetails.price,
      });
      setDistance_priceWithCommas("");
    } else {
      setData({
        ...data,
        confirmed_price_type: "percent",
        confirmed_price_percent: "",
        confirmed_price: itemDetails.price,
      });
    }
    setErrors({});
  };

  const handleChange = (e) => {
    let reqularPrice =
      (parseInt(e.currentTarget.value) / 100) * parseInt(itemDetails.price);
    if (!parseInt(e.currentTarget.value)) {
      setData({
        ...data,
        [e.currentTarget.id]: e.currentTarget.value,
        confirmed_price: parseInt(itemDetails.price),
      });
    } else {
      setData({
        ...data,
        [e.currentTarget.id]: e.currentTarget.value,
        confirmed_price: parseInt(itemDetails.price) + reqularPrice,
      });
    }
    console.log(data);
  };
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleChangeDistancePrice = (e) => {
    const price = addCommas(removeNonNumeric(e.target.value));
    setDistance_priceWithCommas(price);
    let totalPrice;
    if (!parseInt(price.replaceAll(",", ""))) {
      totalPrice = parseInt(itemDetails.price);
    } else {
      totalPrice =
        parseInt(itemDetails.price) + parseInt(price.replaceAll(",", ""));
    }
    setData({ ...data, confirmed_price: totalPrice });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = validate();
    if (data.confirmed_price_type === "regular") {
      if (!distance_priceWithCommas) {
        toast.warning("میزان سود نمی تواند خالی باشد");
        errors = "distance_priceWithCommas";
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;
    doSubmit();
  };
  const validate = () => {
    const result = schema.validate(data, distance_priceWithCommas, {
      abortEarly: false,
    });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "confirmed_price_percent") {
      errorMessage = "درصد سود نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("accepting request ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put(
        "/company/product/detail/update/" + itemDetails.id,
        data,
        config
      );
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleAcceptRequest(toastId, data);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead operation="ACCEPT">
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("the amount of ROYSA profit")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <DivToggleButton onClick={handleToggleButton}>
                <ToggleButton active={data.confirmed_price_type === "percent"}>
                  {t("percent")}
                </ToggleButton>
                <ToggleButton active={data.confirmed_price_type === "regular"}>
                  {t("amount")}
                </ToggleButton>
              </DivToggleButton>
            </DivInput>
            <DivInput>
              <Lable>
                میزان سود (
                {data.confirmed_price_type === "percent" ? "%" : "تومان"})
              </Lable>
              {data.confirmed_price_type === "percent" ? (
                <Input
                  id="confirmed_price_percent"
                  onChange={handleChange}
                  value={data.confirmed_price_percent}
                  onKeyPress={handleNumber}
                  maxLength={"2"}
                />
              ) : (
                <Input
                  id="distance_price"
                  onChange={handleChangeDistancePrice}
                  value={distance_priceWithCommas}
                  onKeyPress={handleNumber}
                />
              )}
              <Helper>
                قیمت نهایی :
                <Price> {numberWithCommas(data.confirmed_price)} تومان</Price>
              </Helper>
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                {t("accept")}
              </Btn>
              <Btn onClick={CloseModal}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Accept;
