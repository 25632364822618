import React, { Component, useEffect, useState } from "react";
import Search from "../../Components/DeliveryCompanies/Search";
import List from "../../Components/DeliveryCompanies/List";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import ModalAdd from "../../Components/DeliveryCompanies/Modals/Add";
import ModalUpdate from "../../Components/DeliveryCompanies/Modals/Update";
import ModalDelete from "../../Components/DeliveryCompanies/Modals/Delete";

import ModalSelectAdmin from "../../Components/DeliveryCompanies/Modals/SelectAdmin";
import ModalUpdateSelectAdmin from "../../Components/DeliveryCompanies/Modals/UpdateSelectAdmin";
import ModalOperations from "../../Components/DeliveryCompanies/Modals/Operations";

// import AddDeviceModal from "../../Components/Modals/Definitions/AddDeviceModal";
// import axiosConfig from "../../ApiCall/axiosConfig";

const Products = () => {
  const [items, setItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalSelectAdmin, setShowModalSelectAdmin] = useState(false);
  const [showModalUpdateSelectAdmin, setShowModalUpdateSelectAdmin] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalOperations, setShowModalOperations] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleOpenModal = (modal, detailItem) => {
    if (modal === "modalAdd") {
      setShowModalAdd(true);
    } else if (modal === "modalSelectAdmin") {
      setShowModalSelectAdmin(true);
    } else if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(true);
      setDetailItem(detailItem);
    } else if (modal === "modalUpdateSelectAdmin") {
      setShowModalUpdateSelectAdmin(true);
      setDetailItem(detailItem);
    } else if (modal === "modalDelete") {
      setShowModalDelete(true);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalAdd") {
      setAdmin(null);
      setShowModalAdd(false);
    } else if (modal === "modalSelectAdmin") {
      setShowModalSelectAdmin(false);
    } else if (modal === "modalOperations") {
      setShowModalOperations(false);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(false);
    } else if (modal === "modalUpdateSelectAdmin") {
      setShowModalUpdateSelectAdmin(false);
    } else if (modal === "modalDelete") {
      setShowModalDelete(false);
    }
  };
  const handleSelectAdmin = (admin) => {
    setAdmin(admin);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/company/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchData();
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.title.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };
  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/company/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "شرکت پخش با موفقیت ثبت شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };
  const handleDelete = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/company/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "شرکت پخش با موفقیت حذف شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const handleUpdate = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/company/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "شرکت پخش با موفقیت ویرایش شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };
  const handleResetUpdateModal = () => {
    setAdmin(null);
  };

  return (
    <>
      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List
              //   handleUpdate={this.handleUpdate}
              //   handleDelete={this.handleDelete}
              data={items}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </ListWrapper>
        <ModalAdd
          showAddModal={showModalAdd}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleAddNew={handleAddNew}
          admin={admin}
        ></ModalAdd>
        <ModalSelectAdmin
          showModalSelectAdmin={showModalSelectAdmin}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectAdmin={handleSelectAdmin}
        ></ModalSelectAdmin>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleResetUpdateModal={handleResetUpdateModal}
        ></ModalOperations>
        <ModalUpdate
          showUpdateModal={showModalUpdate}
          detailItem={detailItem}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
          admin={admin}
        ></ModalUpdate>
        <ModalUpdateSelectAdmin
          showModalUpdateSelectAdmin={showModalUpdateSelectAdmin}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectAdmin={handleSelectAdmin}
        ></ModalUpdateSelectAdmin>
        <ModalDelete
          showModalDelete={showModalDelete}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        ></ModalDelete>
      </Container>
    </>
  );
};

export default Products;
