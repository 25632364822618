import styled from "styled-components";
export const Container = styled.div`
  height: 97%;
  width: 88vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 3%;
  row-gap: 2vh;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Item = styled.div`
  font-size: 0.8vw;
  width: 31%;
  position: relative;
  padding-inline-start: 0.4vw;
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline-start: 2.2vw;
  top: 2.8vw;
  display: flex;
  column-gap: 1.4vw;
  align-items: flex-start;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  position: absolute;
  inset-inline-start: 0.2vw;
  top: 1.4vw;
  height: 3.8vw;
  min-width: 3.8vw;
  max-width: 3.8vw;
  border: solid 0.2vw var(--backgroundListWrapper);
  background: white;
  border-radius: 1vw;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;
export const Title = styled.p`
  font-size: 1vw;
  font-weight: 700;
  padding-inline-start: 3vw;
  padding-inline-end: 1.3vw;
`;
export const Detail = styled.div`
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  padding-top: 1.8vw;
  color: var(--lightBrown);
  height: 3.3vw;
  gap: 1.2vw;
`;

export const DivBranches = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.82vw;
  font-weight: 600;
  height: 100%;
  gap: 0.3vw;
`;
export const Branches = styled.p``;
export const DivAdmin = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.82vw;
  font-weight: 600;
  height: 100%;
  gap: 0.3vw;
`;
export const Admin = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 34ch;
  font-weight: ${({ empty }) => (empty ? "400" : "600")};
`;
export const IconDetail = styled.img`
  height: 100%;
`;

export const Btn = styled.button`
  position: absolute;
  inset-inline-end: 0.7vw;
  bottom: 1.2vw;
  height: 2.7vw;
  width: 2.7vw;
  border-radius: 1vw;
  border: none;
  margin-right: auto;
  background: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--lightBrown);
  }
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const BtnAdd = styled.button`
  position: absolute;
  inset-inline-start: 1vw;
  bottom: 1.8vh;
  height: 2.8vw;
  border-radius: 1vw;
  border: none;
  background: var(--brown);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  font-weight: 600;
  font-size: 0.9vw;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
  }
`;
export const IconBtnAdd = styled.img`
  height: 100%;
`;
