import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivIconHead,
  IconHead,
  DivSelectAdmin,
  User,
  CoverImgProfile,
  ImgProfile,
  InputSearch,
  DivInputSearch,
  SearchIcon,
} from "./Element";
import icon from "../../../Images/defaultAvatar.svg";
import searchIcon from "../../../Images/search.svg";

import iconUser from "../../../Images/usernameIcon.svg";
import { useTranslation } from "react-i18next";
// import AddRoleForm from "../../Form/AddRoleForm";

const SelectAdmin = ({
  showModalSelectAdmin,
  handleCloseModal,
  handleOpenModal,
  handleSelectAdmin,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalSelectAdmin);
    if (showModalSelectAdmin) {
      setLoading(true);
      async function fetchPermissions() {
        const token = localStorage.getItem("tokenPanelRoysa");
        const params = new URLSearchParams([
          ["per_page", 1000000],
          ["page", 1],
        ]);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };

        try {
          const { data: usersData } = await axiosConfig.get(
            "/users/rank/10",
            config
          );
          let arrayUsers = [];
          usersData.data.forEach((element) => {
            if (!element?.company) {
              arrayUsers.push(element);
            }
          });
          setUsers(arrayUsers);
          setSearchItems(arrayUsers);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching permissions:", error);
        }
      }
      fetchPermissions();
    }
  }, [showModalSelectAdmin]);

  const CloseModal = () => {
    handleCloseModal("modalSelectAdmin");
    handleOpenModal("modalAdd");
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        let nameFamily = item.name + " " + item.family;

        return (
          nameFamily.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.family.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setUsers(results);
    } else {
      setUsers(searchItems);
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("choose delivery category admin")}</TitleHead>
          </Header>
          <Body>
            <DivInputSearch>
              <SearchIcon src={searchIcon} />
              <InputSearch
                placeholder="جستجوی کاربر ..."
                id="search"
                onChange={filter}
              />
            </DivInputSearch>
            <DivSelectAdmin>
              {loading ? (
                <div className="loadingList">
                  <div className="spinner"></div>
                </div>
              ) : (
                users.map((item) => (
                  <User
                    onClick={() => {
                      handleSelectAdmin(item);
                      CloseModal();
                    }}
                  >
                    <CoverImgProfile>
                      <ImgProfile
                        haveAvatar={item.avatar ? true : false}
                        src={item.avatar ? item.avatar : iconUser}
                      />
                    </CoverImgProfile>
                    {item.name + " " + item.family}
                  </User>
                ))
              )}
            </DivSelectAdmin>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default SelectAdmin;
