import styled from "styled-components";
export const Container = styled.div`
  grid-area: col2;
  height: 88vh;
  width: 100%;
  border-radius: 1.6vw;
  background-color: var(--backgroundListWrapper);
  margin-bottom: 2vh;
  position: relative;
`;
export const ListWrapper = styled.div`
  height: 81vh;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 7vh;
  width: 100%;
`;
export const IndividualShiftAssignmentSearchWrapper = styled.div`
  padding: 5vh 4.5vw;
  display: flex;
  align-items: flex-start;
  height: 15%;
  width: 100%;
  justify-content: space-between;
`;
export const SearchInput = styled.div`
  background-color: black;
  display: flex;
  height: 20%;
  width: 100%;
  border-radius: 1.6vh;
`;
export const ListWrapperDashboard = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1.6vh;
`;

export const ListWrapperReports = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1.6vh;
`;
export const ContainerRequestsCompanies = styled.div`
  grid-area: col2;
  height: 88vh;
  width: 100%;
  border-radius: 1.6vw;
  background-color: var(--backgroundListWrapper);
  margin-bottom: 2vh;
  display: flex;
`;
export const ContainerCompanies = styled.div`
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
`;
export const ContainerRequest = styled.div`
  height: 100%;
  width: 65%;
  display: flex;
`;
export const ContainerRequestsTradeUnions = styled.div`
  grid-area: col2;
  height: 88vh;
  width: 100%;
  border-radius: 1.6vw;
  background-color: var(--backgroundListWrapper);
  margin-bottom: 2vh;
  display: flex;
`;
export const ContainerTradeUnions = styled.div`
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
`;
