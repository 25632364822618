import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DashedSubCategories,
  DivBoxSubCategories,
  DivCategory,
  DivCategories,
  DivSelect,
  DivSubCategory,
  DivSubCategories,
  Input,
  DivInput,
  Lable,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  CoverImg,
  Img,
  DivRow,
  DivColumn,
  CoverImgUser,
  ImgUser,
  DivSelectUser,
  SelectUser,
  ActivCategoryCheckbox,
  ActiveSubCategoryCheckbox,
  ImgCategory,
  MainSelect,
  ChexBoxDiv,
  ChexBox,
  ToggleButton,
  DivToggleButton,
  DivRowCheckBox,
} from "./Element";
import icon from "../../../Images/headerIcons/deliveryCompanies.svg";
import tickIcon from "../../../Images/tick.svg";
import iconSelect from "../../../Images/selectImg/deliveryCategories.svg";
import iconUser from "../../../Images/usernameIcon.svg";

import { useTranslation } from "react-i18next";
const Update = ({
  lastId,
  showUpdateModal,
  handleUpdate,
  handleCloseModal,
  handleOpenModal,
  admin,
  detailItem,
}) => {
  const [data, setData] = useState({
    title: "",
    logo: null,
    user_id: null,
    status: 1,
    categories: [],
  });
  const [errors, setErrors] = useState({});
  const [items, setItems] = useState([]);
  const [parents, setParents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [distancePriceWithCommas, setDistancePriceWithCommas] = useState("");
  const [confirmedPricePercent, setConfirmedPricePercent] = useState("");

  useEffect(() => {
    setShowModal(showUpdateModal);
    if (showUpdateModal) {
      setLoading(true);
      async function fetchCategories() {
        const token = localStorage.getItem("tokenPanelRoysa");
        const params = new URLSearchParams([
          ["per_page", 1000000],
          ["page", 1],
        ]);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };

        try {
          const { data: categoriesData } = await axiosConfig.get(
            "/category/list",
            config
          );
          setItems(categoriesData);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching Categories:", error);
        }
      }
      fetchCategories();
      let categoriesArray = [];
      let parentsArray = [];
      if (admin) {
        setUser(admin);
        setData({ ...data, user_id: admin.id });
      } else {
        detailItem?.categories.forEach((category) => {
          categoriesArray.push(category?.id);
          if (category?.parent) {
            parentsArray.push(category?.parent?.id);
          }
        });
        setParents(parentsArray);
        setUser(detailItem?.user);
        setDistancePriceWithCommas(
          numberWithCommas(detailItem?.confirmed_price)
        );
        setConfirmedPricePercent(detailItem?.confirmed_price_percent);
        const imageUrl = detailItem?.logo;
        // Check if imageUrl is available
        if (imageUrl) {
          fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Image = reader.result;
                setData({
                  ...data,
                  logo: base64Image,
                  title: detailItem?.title,
                  automatic_confirmation_product:
                    detailItem?.automatic_confirmation_product,
                  automatic_confirmation_slider:
                    detailItem?.automatic_confirmation_slider,
                  confirmed_price_type: detailItem?.confirmed_price_type,
                  confirmed_price: detailItem?.confirmed_price,
                  confirmed_price_percent: detailItem?.confirmed_price_percent,
                  categories: categoriesArray,
                  user_id: detailItem?.user?.id,
                  status: 1,
                });
              };
              reader.readAsDataURL(blob);
            })
            .catch((error) => {
              console.error("Error fetching or converting the image:", error);
            });
        } else {
          console.log(detailItem?.categories);
          setData({
            ...data,
            logo: detailItem?.logo,
            title: detailItem?.title,
            automatic_confirmation_product:
              detailItem?.automatic_confirmation_product,
            automatic_confirmation_slider:
              detailItem?.automatic_confirmation_slider,
            confirmed_price_type: detailItem?.confirmed_price_type,
            confirmed_price: detailItem?.confirmed_price,
            confirmed_price_percent: detailItem?.confirmed_price_percent,
            categories: categoriesArray,
            user_id: detailItem?.user?.id,
            status: 1,
          });
        }
      }
    }
  }, [lastId, showUpdateModal]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const schema = Joi.object({
    title: Joi.string().required(),
    logo: [Joi.string().optional(), Joi.allow(null)],
    user_id: Joi.number().integer().required(),
    categories: Joi.array().min(1).required(),
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const handleOpenModalSelectAdmin = () => {
    handleCloseModal("modalUpdate");
    handleOpenModal("modalUpdateSelectAdmin", {
      ...data,
      id: detailItem?.id,
      user: detailItem?.user,
      categories: detailItem?.categories,
    });
  };

  const CloseModal = () => {
    handleCloseModal("modalUpdate");
    setUser(null);
    setErrors({});
  };
  const handleAutomaticConfirmationProduct = () => {
    setData({
      ...data,
      automatic_confirmation_product: !data?.automatic_confirmation_product,
    });
  };

  const handleAutomaticConfirmationSlider = () => {
    setData({
      ...data,
      automatic_confirmation_slider: !data?.automatic_confirmation_slider,
    });
  };
  const handleToggleButton = () => {
    if (data.confirmed_price_type === "percent") {
      setData({
        ...data,
        confirmed_price_type: "regular",
        confirmed_price_percent: "0",
        confirmed_price: "0",
      });
      setDistancePriceWithCommas("");
    } else {
      setData({
        ...data,
        confirmed_price_type: "percent",
        confirmed_price_percent: "0",
        confirmed_price: "0",
      });
      setConfirmedPricePercent("");
    }
  };

  const selectCategory = (category) => {
    let updatedCategories;
    let updatedParents = parents;
    const isCategorySelected = data.categories.includes(category.id);
    if (isCategorySelected) {
      // Unselect the category
      updatedCategories = data.categories.filter(
        (categoryId) => categoryId !== category.id
      );
      // console.log(updatedCategories);
      category.childs.forEach((subCategory) => {
        updatedCategories = updatedCategories.filter(
          (subCategoryId) => subCategoryId !== subCategory.id
        );
        // console.log(updatedCategories);
        updatedParents = parents.filter(
          (categoryId) => categoryId !== category.id
        );
        // console.log(updatedParents);
      });
    } else {
      // Select the category
      updatedCategories = [...data.categories, category.id];
      category.childs.forEach((subCategory) => {
        if (!updatedCategories.includes(subCategory.id)) {
          updatedParents.push(category.id);
          updatedCategories.push(subCategory.id);
        }
      });
      // console.log(updatedParents);
      // console.log(updatedCategories);
    }
    setData({ ...data, categories: updatedCategories });
    setParents(updatedParents);
  };
  const selectSubCategory = (subCategory) => {
    let updatedCategories;
    let updatedParents;
    let found = false;
    const subCategoryParent = parseInt(subCategory.parent_id);
    const isSubCategorySelected = data.categories.includes(subCategory.id);
    const isParentCategorySelected =
      data.categories.includes(subCategoryParent);

    if (isSubCategorySelected) {
      // Unselect the subcategory
      updatedCategories = data.categories.filter(
        (categoryId) => categoryId !== subCategory.id
      );
      // console.log(updatedCategories);

      updatedParents = parents.filter((parentId) => {
        if (!found && parentId === subCategoryParent) {
          found = true;
          return false; // Skip the first occurrence
        }
        return true; // Keep all other elements
      });
      // console.log(updatedParents);

      if (!updatedParents.includes(subCategoryParent)) {
        // console.log("first");
        updatedCategories = updatedCategories.filter(
          (categoryId) => categoryId !== subCategoryParent
        );
      }
      // console.log(updatedCategories);
    } else {
      // Select the subcategory
      updatedCategories = [...data.categories, subCategory.id];
      if (!isParentCategorySelected) {
        updatedCategories.push(subCategoryParent);
      }
      updatedParents = [...parents, subCategoryParent];
    }
    // console.log(updatedCategories);
    // console.log(updatedParents);

    // Check if the parent category needs to be added

    setData({ ...data, categories: updatedCategories });
    setParents(updatedParents);
  };

  const handleChange = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleChangePercent = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
    setConfirmedPricePercent(e.currentTarget.value);
  };
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
  const handleChangeDistancePrice = (e) => {
    const price = addCommas(removeNonNumeric(e.target.value));
    setDistancePriceWithCommas(price);
    setData({ ...data, confirmed_price: price.replaceAll(",", "") });
  };

  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      if (data.automatic_confirmation_product) {
        if (data.confirmed_price_type === "regular") {
          if (distancePriceWithCommas === "") {
            toast.warning("مبلغ سود رویسا نمیتواند خالی باشد");
          } else {
            setDisabledBtn(true);
            doSubmit();
          }
        } else {
          if (confirmedPricePercent === "") {
            toast.warning("درصد سود رویسا نمیتواند خالی باشد");
          } else {
            setDisabledBtn(true);
            doSubmit();
          }
        }
      } else {
        setDisabledBtn(true);
        doSubmit();
      }
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "user_id") {
      errorMessage = "لطفا مدیر شرکت پخش را انتخاب کنید";
    } else if (result.error.details[0].path[0] === "categories") {
      errorMessage = "حداقل یک دسته کالایی را اختصاص دهید";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const selectFile = () => {
    document.getElementById("fname").click();
  };

  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setData({ ...data, logo: base64Image });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
      console.log(data);
    }
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading("در حال ویرایش شرکت پخش ...");
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      if (!admin) {
        await axiosConfig.put("/company/update/" + detailItem.id, data, config);
      } else {
        await axiosConfig.put("/company/update/" + detailItem.id, data, config);
        await axiosConfig.put(
          "/company/user/update/" + admin?.id,
          {
            roles: ["Super Admin Delivery Company" + detailItem?.id],
          },
          config
        );
        await axiosConfig.put(
          "/company/user/update/" + detailItem?.user?.id,
          {
            roles: [],
          },
          config
        );
      }
      isOkResponse = true;
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      console.log(errors);
      toast.update(toastId, {
        render: "خطا در اتصال به سرور",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      handleUpdate(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("update delivery company")}</TitleHead>
          </Header>
          <Body>
            <DivRow>
              <DivColumn>
                <DivInput>
                  <Lable>
                    <br />
                  </Lable>
                  <CoverImg onClick={selectFile}>
                    <Img src={data?.logo ? data?.logo : iconSelect} />
                  </CoverImg>
                  <input
                    hidden
                    type="file"
                    id="fname"
                    name="fname"
                    onChange={onSelectImage}
                  ></input>
                </DivInput>
                <DivInput>
                  <Lable>{t("title delivery company")}</Lable>
                  <Input
                    id="title"
                    onChange={handleChange}
                    value={data.title}
                  />
                </DivInput>
                <DivSelectUser>
                  <CoverImgUser>
                    {user ? (
                      <ImgUser
                        haveAvatar={user?.avatar ? true : false}
                        src={user?.avatar ? user?.avatar : iconUser}
                      />
                    ) : (
                      <ImgUser src={iconUser} />
                    )}
                  </CoverImgUser>
                  <SelectUser onClick={handleOpenModalSelectAdmin}>
                    {user
                      ? user.name + " " + user.family
                      : t("choose delivery category admin")}
                  </SelectUser>
                </DivSelectUser>
              </DivColumn>
              <DivColumn>
                <MainSelect>
                  <Lable>{t("asign of category")}</Lable>
                  <DivSelect>
                    {loading ? (
                      <div className="loadingList">
                        <div className="spinner"></div>
                      </div>
                    ) : (
                      items?.data?.map((item) => (
                        <DivCategories key={item?.id}>
                          <DivCategory>
                            <ActivCategoryCheckbox
                              active={
                                data?.categories?.includes(item?.id)
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                selectCategory(item);
                              }}
                            >
                              <ImgCategory src={item?.image} />
                            </ActivCategoryCheckbox>
                            {item?.title}
                          </DivCategory>
                          <DivBoxSubCategories>
                            <DashedSubCategories />
                            <DivSubCategories>
                              {item?.childs?.map((subItem) => (
                                <DivSubCategory key={subItem?.id}>
                                  <ActiveSubCategoryCheckbox
                                    active={
                                      data?.categories?.includes(subItem?.id)
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      selectSubCategory(subItem);
                                    }}
                                  />
                                  {subItem?.title}
                                </DivSubCategory>
                              ))}
                            </DivSubCategories>
                          </DivBoxSubCategories>
                        </DivCategories>
                      ))
                    )}
                  </DivSelect>
                </MainSelect>
              </DivColumn>
            </DivRow>
            <DivRow>
              <ChexBoxDiv
                onClick={handleAutomaticConfirmationProduct}
                active={data.automatic_confirmation_product}
              >
                <ChexBox active={data.automatic_confirmation_product}>
                  <Img src={tickIcon} />
                </ChexBox>
                تایید خودکار اطلاعات محصول
              </ChexBoxDiv>
              <ChexBoxDiv
                onClick={handleAutomaticConfirmationSlider}
                active={data.automatic_confirmation_slider}
              >
                <ChexBox active={data.automatic_confirmation_slider}>
                  <Img src={tickIcon} />
                </ChexBox>
                تایید خودکار اسلایدر
              </ChexBoxDiv>
            </DivRow>
            <DivRowCheckBox active={data.automatic_confirmation_product}>
              <DivInput>
                <DivToggleButton
                  onClick={handleToggleButton}
                  disabled={!data.automatic_confirmation_product}
                >
                  <ToggleButton
                    disabled={!data.automatic_confirmation_product}
                    active={data.confirmed_price_type === "percent"}
                  >
                    {t("percent")}
                  </ToggleButton>
                  <ToggleButton
                    disabled={!data.automatic_confirmation_product}
                    active={data.confirmed_price_type === "regular"}
                  >
                    {t("amount")}
                  </ToggleButton>
                </DivToggleButton>
              </DivInput>

              <DivInput>
                {data.confirmed_price_type === "percent" ? (
                  <Input
                    disabled={!data.automatic_confirmation_product}
                    id="confirmed_price_percent"
                    onChange={handleChangePercent}
                    value={confirmedPricePercent}
                    onKeyPress={handleNumber}
                    maxLength={"2"}
                    placeholder={"میزان درصد سود رویسا"}
                  />
                ) : (
                  <Input
                    disabled={!data.automatic_confirmation_product}
                    id="distance_price"
                    onChange={handleChangeDistancePrice}
                    value={distancePriceWithCommas}
                    onKeyPress={handleNumber}
                    placeholder={"میزان مبلغ سود رویسا"}
                  />
                )}
              </DivInput>
            </DivRowCheckBox>
            <DivRow>
              <DivColumn>
                <BtnDiv btnPadding></BtnDiv>
              </DivColumn>
              <DivColumn>
                <BtnDiv btnPadding={true}>
                  <Btn okBtn onClick={handleSubmit}>
                    {t("edit")}
                  </Btn>
                  <Btn onClick={CloseModal}>{t("cancel")}</Btn>
                </BtnDiv>
              </DivColumn>
            </DivRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Update;
