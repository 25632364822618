import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Lable,
  Textarea,
  DivText,
} from "./Element";
import icon from "../../../Images/rejectIcon.svg";
import { useTranslation } from "react-i18next";

const Reject = ({
  showModalReject,
  handleRejectRequest,
  handleCloseModal,
  handleOpenModal,
  itemDetails,
}) => {
  const [data, setData] = useState({
    image: "",
    company_id: null,
    link: "",
    position: null,
    status: "deny",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalReject);
    if (showModalReject) {
      const imageUrl = itemDetails?.image;

      // Check if imageUrl is available
      if (imageUrl) {
        fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64Image = reader.result;

              setData({
                ...data,
                image: base64Image,
                company_id: itemDetails?.company?.id,
                link: itemDetails?.link,
                position: itemDetails?.position,
                status: "deny",
                description: "",
              });
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) => {
            console.error("Error fetching or converting the image:", error);
          });
      }
    }
  }, [showModalReject, itemDetails]);
  const CloseModal = () => {
    handleCloseModal("modalReject");
    setData({
      image: "",
      company_id: null,
      link: "",
      position: null,
      status: "deny",
      description: "",
    });
    setErrors({});
  };

  const handleChange = (e) => {
    console.log(data);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("در حال رد اسلایدر ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put("/slider/update/" + itemDetails.id, data, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleRejectRequest(toastId, data);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead operation="REJECT">
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("علت رد اسلایدر")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <Lable>علت</Lable>
              <Textarea
                id="description"
                onChange={handleChange}
                value={data.description}
              />
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={doSubmit}>
                {t("reject")}
              </Btn>
              <Btn onClick={CloseModal}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Reject;
