import {
  Container,
  Btn,
  DetailItem,
  Img,
  Header,
  DivIconHead,
  IconHead,
  TitleHead,
  Head,
  DivTime,
  CalendarTime,
  ClockTime,
  SeparatorTime,
  Body,
  BtnDiv,
  TitleBtn,
  BtnIcon,
  Footer,
  ImgDefault,
  DivEndHead,
  StatusDiv,
  DivResponse,
  Span,
  LableDetailRequest,
  DivImg,
  ImgSlider,
  LinkSlider,
  DivSlider,
} from "./ElementCompaniesRequests";
import icon from "../../Images/headerIcons/deliveryCompanies.svg";
import acceptIcon from "../../Images/acceptIcon.svg";
import rejectIcon from "../../Images/rejectIcon.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import defaultBackground from "../../Images/defaultBackground.svg";

import Joi from "joi";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

const List = ({ detailItem, handleOpenModal, handleAccept }) => {
  const [item, setItem] = useState(null);
  const [data, setData] = useState({
    image: "",
    company_id: null,
    link: "",
    position: null,
    status: "agree",
    description: "",
  });
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (detailItem) {
      console.log(detailItem);
      const imageUrl = detailItem?.image;

      // Check if imageUrl is available
      if (imageUrl) {
        fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64Image = reader.result;
              setData({
                ...data,
                image: base64Image,
                company_id: detailItem?.company?.id,
                link: detailItem?.link,
                position: detailItem?.position,
                status: "agree",
                description: "",
              });
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) => {
            console.error("Error fetching or converting the image:", error);
          });
      }
      setItem(detailItem);
    }
  }, [detailItem]);
  const getTime = (date) => {
    date = date.split(" ");
    return date[3];
  };
  const getCalendar = (date) => {
    date = date.split(" ");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return date[2] + "/" + date[1] + "/" + date[0];
  };
  const doAccept = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("در حال تایید اسلایدر ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put("/slider/update/" + detailItem.id, data, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleAccept(toastId, data, detailItem.id);
    }
  };
  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container UnPaddingStart>
        <DetailItem>
          {!item ? (
            <ImgDefault src={defaultBackground} />
          ) : (
            <>
              <Header>
                <Head>
                  <DivIconHead
                    isLogo={item?.company?.logo}
                    status={
                      (item?.status === "pending" && "PENDING") ||
                      (item?.status === "agree" && "AGREE") ||
                      (item?.status === "deny" && "DENY")
                    }
                  >
                    <IconHead
                      isLogo={item?.company?.logo}
                      src={item?.company?.logo ? item?.company?.logo : icon}
                    />
                  </DivIconHead>
                  <TitleHead>{item?.company?.title}</TitleHead>
                </Head>
                <DivEndHead
                  status={
                    (item?.status === "pending" && "PENDING") ||
                    (item?.status === "agree" && "AGREE") ||
                    (item?.status === "deny" && "DENY")
                  }
                >
                  {((item?.status === "agree" && "AGREE") ||
                    (item?.status === "deny" && "DENY")) && (
                    <>
                      <StatusDiv
                        status={
                          (item?.status === "agree" && "AGREE") ||
                          (item?.status === "deny" && "DENY")
                        }
                      >
                        <Img
                          src={
                            (item?.status === "agree" && agreeIcon) ||
                            (item?.status === "deny" && denyIcon)
                          }
                        />
                      </StatusDiv>
                      {(item?.status === "agree" && "تایید شده") ||
                        (item?.status === "deny" && "رد شده")}
                    </>
                  )}

                  <DivTime
                    status={
                      (item?.status === "pending" && "PENDING") ||
                      (item?.status === "agree" && "AGREE") ||
                      (item?.status === "deny" && "DENY")
                    }
                  >
                    <ClockTime>{getTime(item?.jalali_created_at)}</ClockTime>
                    <SeparatorTime
                      status={
                        (item?.status === "pending" && "PENDING") ||
                        (item?.status === "agree" && "AGREE") ||
                        (item?.status === "deny" && "DENY")
                      }
                    />
                    <CalendarTime>
                      {getCalendar(item?.jalali_created_at)}
                    </CalendarTime>
                  </DivTime>
                </DivEndHead>
              </Header>
              <Body>
                <DivSlider>
                  <DivImg>
                    <ImgSlider src={item?.image} />
                  </DivImg>
                  <LinkSlider>{item?.link}</LinkSlider>
                </DivSlider>
              </Body>
              <Footer>
                {(item?.status === "pending" && (
                  <BtnDiv btnPadding={true}>
                    <Btn operation="ACCEPT" onClick={() => doAccept(item)}>
                      <BtnIcon src={acceptIcon} />
                      <TitleBtn>{t("accept")}</TitleBtn>
                    </Btn>
                    <Btn
                      operation="REJECT"
                      onClick={() => handleOpenModal("modalReject", item)}
                    >
                      <BtnIcon src={rejectIcon} />
                      <TitleBtn>{t("reject")}</TitleBtn>
                    </Btn>
                  </BtnDiv>
                )) ||
                  (item?.status === "agree" && (
                    <DivResponse>
                      موقعیت نمایش اسلایدر
                      <Span> &nbsp;{item?.position} &nbsp;</Span>
                      می باشد.
                    </DivResponse>
                  )) ||
                  (item?.status === "deny" && (
                    <DivResponse reject>
                      <LableDetailRequest>علت رد :</LableDetailRequest>
                      &nbsp;
                      {item?.description}
                    </DivResponse>
                  ))}
              </Footer>
            </>
          )}
        </DetailItem>
      </Container>
    </>
  );
};

export default List;
