import {
  Container,
  Tabs,
  Tab,
  TabLink,
  TabTitle,
  Profile,
  ProfileCover,
  ProfileImg,
  DivProfile,
  DetailUser,
  Name,
  Role,
} from "./TabsElements";
import profileCover from "../../Images/profileCover.svg";
import profile from "../../../src/Images/defaultAvatar.svg";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const TabsComponent = () => {
  const [subMenus, setSubMenus] = useState([]);
  const [activeSubMenuId, setActiveSubMenuId] = useState(null);
  const firstNavLinkRef = useRef(null);
  const [user, setUser] = useState(null);

  const location = useLocation();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userPanelRoysa")));
    if (location.state?.sub_menus) {
      setSubMenus(location.state?.sub_menus);
    }
    // Check if firstNavLinkRef is defined and click it
    if (firstNavLinkRef.current) {
      firstNavLinkRef.current.click();
    }
  }, [location.state?.sub_menus, subMenus]);

  const changeActive = (subMenu) => {
    setActiveSubMenuId(subMenu.id);
  };

  return (
    <>
      <Container>
        <Tabs>
          {subMenus.map((subMenu, index) => (
            <Tab
              onClick={() => {
                changeActive(subMenu);
              }}
              active={subMenu.id === activeSubMenuId ? true : false}
              key={subMenu.id}
            >
              <TabLink
                ref={index === 0 ? firstNavLinkRef : null} // Assign the ref to the first NavLink
                active={subMenu.id === activeSubMenuId ? true : false}
                to={subMenu.url}
                onClick={() => changeActive(subMenu)}
              >
                <TabTitle>{subMenu.title}</TabTitle>
              </TabLink>
            </Tab>
          ))}
        </Tabs>
        <DetailUser>
          <Name>{user?.name + " " + user?.family}</Name>
          <Role>{user?.role_persian_title}</Role>
        </DetailUser>
        <Profile>
          <DivProfile>
            <ProfileCover src={profileCover} />
            <ProfileImg
              defaultImg={!user?.avatar}
              src={user?.avatar ? user.avatar : profile}
            />
          </DivProfile>
        </Profile>
      </Container>
    </>
  );
};

export default TabsComponent;
