import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Lable,
  Textarea,
} from "./Element";
import icon from "../../../Images/rejectIcon.svg";
import { useTranslation } from "react-i18next";

const Reject = ({
  showModalReject,
  handleRejectRequest,
  handleCloseModal,
  handleOpenModal,
  itemDetails,
}) => {
  const [data, setData] = useState({
    price: "",
    confirmed_price_type: "regular", //reqular or percent
    confirmed_price_percent: 0,
    weight: "",
    description: "",
    register_at: "",
    confirmed_description: "",
    confirmed_price: 0,
    discount_type: "none",
    status: 2,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalReject);
    console.log(itemDetails);
    if (showModalReject) {
      setData({
        ...data,
        price: itemDetails?.price,
        weight: itemDetails?.weight,
        description: itemDetails?.description,
        register_at: itemDetails?.register_at,
        discount_type: itemDetails?.discount_type,
        discount_value: itemDetails?.discount_value.toString(),
        discount_start_at: itemDetails?.discount_start_at,
        discount_end_at: itemDetails?.discount_end_at,
      });
    }
  }, [showModalReject, itemDetails]);

  const schema = Joi.object({
    price: [Joi.number().integer().required(), Joi.allow(null)],
    confirmed_price_type: Joi.string().required(),
    confirmed_price_percent: [
      Joi.number().integer().required(),
      Joi.allow(null),
    ],
    weight: [Joi.number().integer().required(), Joi.allow(null)],
    description: Joi.string().required(),
    register_at: Joi.string().required(),
    confirmed_description: Joi.string().required(),
    discount_type: Joi.string().optional(),
    discount_value: Joi.string().optional(),
    discount_start_at: Joi.string().optional(),
    discount_end_at: Joi.string().optional(),

    confirmed_price: [Joi.number().integer().required(), Joi.allow(null)],
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const CloseModal = () => {
    handleCloseModal("modalReject");
    setData({
      price: "",
      confirmed_price_type: "regular", //reqular or percent
      confirmed_price_percent: 0,
      weight: "",
      description: "",
      register_at: "",
      confirmed_description: "",
      confirmed_price: 0,
      discount_type: "none",
      status: 2,
    });
    setErrors({});
  };

  const handleChange = (e) => {
    console.log(data);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setErrors({ errors: errors || {} });
    if (errors) return;
    doSubmit();
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "confirmed_description") {
      errorMessage = "علت درخواست نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("rejecting request ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put(
        "/company/product/detail/update/" + itemDetails.id,
        data,
        config
      );
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleRejectRequest(toastId, data);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead operation="REJECT">
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("reason of reject request")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <Lable>{t("reason")}</Lable>
              <Textarea
                id="confirmed_description"
                onChange={handleChange}
                value={data.confirmed_description}
              />
            </DivInput>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                {t("reject")}
              </Btn>
              <Btn onClick={CloseModal}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Reject;
