import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BoxInputLogin,
  BoxLogin,
  BoxOrganization,
  Container,
  Input,
  DivInput,
  LogoOrganization,
  Btn,
  Footer,
  DivFooterTitle,
  InputPassword,
  BtnShowPassword,
  ImgShowPassword,
  DivInputPassword,
  IconInput,
  DivInputUsername,
  FooterTitle,
} from "./LoginElements";

import logo from "../../Images/whiteLogo.svg";
import enLogo from "../../Images/enWhiteLogo.svg";
import Eye_slash from "../../Images/Eye_slash.svg";
import Eye from "../../Images/Eye.svg";
import usernameIcon from "../../Images/usernameIcon.svg";
import passwordIcon from "../../Images/passwordIcon.svg";

import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [imgShowPassword, setImgShowPassword] = useState(Eye_slash);
  const currentLanguageCode = Cookies.get("i18next");

  const navigate = useNavigate();

  const handleChangeUsername = (e) => {
    setUsername(e.currentTarget.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.currentTarget.value);
  };

  const handleShowPassword = () => {
    if (imgShowPassword === Eye_slash) {
      setImgShowPassword(Eye);
    } else {
      setImgShowPassword(Eye_slash);
    }
    const passwordInput = document.getElementById("password");
    const type =
      passwordInput.getAttribute("type") === "password" ? "text" : "password";
    passwordInput.setAttribute("type", type);
  };

  const handleLoginButton = () => {
    loginRequest(username, password);
  };
  const { t } = useTranslation();

  const loginRequest = async (username, password) => {
    let isOkResponse;
    if (username === "") {
      toast.warning(t("username cannot be empty"), {
        isLoading: false,
        autoClose: 2000,
        closeOnClick: true,
      });
    } else if (password === "") {
      toast.warning(t("password cannot be empty"), {
        isLoading: false,
        autoClose: 2000,
        closeOnClick: true,
      });
    } else {
      const toastId = toast.loading(t("entering ..."));
      await axiosConfig
        .post("/login", { username: username, password: password })
        .then(function (response) {
          isOkResponse = true;
          console.log(response);
          console.log(response.data.data.token);
          localStorage.setItem("tokenPanelRoysa", response.data.data.token);
          localStorage.setItem(
            "userPanelRoysa",
            JSON.stringify(response.data.data.user)
          );

          toast.update(toastId, {
            render: t("login was successful"),
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        })
        .catch(function (error) {
          isOkResponse = false;
          console.log(error);
          toast.update(toastId, {
            render: t("the username or password is incorrect"),
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        });
      if (isOkResponse) {
        navigate("/dashboard");
      }
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container>
        <BoxLogin>
          <BoxOrganization>
            <LogoOrganization
              src={currentLanguageCode === "fa" ? logo : enLogo}
            />
          </BoxOrganization>
          <BoxInputLogin>
            <DivInput>
              <DivInputUsername>
                <IconInput src={usernameIcon} />
                <Input
                  id="username"
                  placeholder={t("username")}
                  value={username}
                  onChange={handleChangeUsername}
                />
              </DivInputUsername>
            </DivInput>
            <DivInput>
              <DivInputPassword>
                <IconInput src={passwordIcon} />
                <InputPassword
                  type="password"
                  id="password"
                  placeholder={t("password")}
                  value={password}
                  onChange={handleChangePassword}
                />
                <BtnShowPassword onClick={handleShowPassword} okBtn>
                  <ImgShowPassword src={imgShowPassword} />
                </BtnShowPassword>
              </DivInputPassword>
            </DivInput>
            <DivInput>
              <Btn onClick={handleLoginButton} okBtn>
                {t("login")}
              </Btn>
            </DivInput>
          </BoxInputLogin>
        </BoxLogin>
        <Footer>
          <DivFooterTitle>
            <FooterTitle>Royal Services Application</FooterTitle>
          </DivFooterTitle>
        </Footer>
      </Container>
    </>
  );
};

export default Login;
