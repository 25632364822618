import { useEffect, useState } from "react";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Item,
  DivItemTitle,
  DivOperations,
  Operation,
  IconOperation,
  BtnAdd,
  IconBtnAdd,
  DivList,
  IconOperationHead,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/categories.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";
import addIcon from "../../../Images/addIcon.svg";

import { useTranslation } from "react-i18next";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleOpenModalAddSubCategory = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalAddSubCategory", detailItem);
  };
  const handleUpdate = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete", detailItem);
  };
  const handleUpdateSubCategory = (detailSubItem) => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdateSubCategory", detailItem, detailSubItem);
  };
  const handleDeleteSubCategory = (detailSubItem) => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDeleteSubCategory", detailItem, detailSubItem);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.image ? true : false}
                  src={item?.image ? item?.image : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn onClick={handleUpdate} operation="EDIT">
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <DivItems>
              <Lable>{t("related sub categories")}</Lable>
              <ListItems>
                <DivList>
                  {item?.childs?.map((child) => (
                    <Item>
                      <DivItemTitle>{child.title}</DivItemTitle>
                      <DivOperations>
                        <Operation
                          onClick={() => handleUpdateSubCategory(child)}
                          operation="EDIT"
                        >
                          <IconOperation src={editIcon} />
                        </Operation>
                        <Operation
                          onClick={() => handleDeleteSubCategory(child)}
                          operation="DELETE"
                        >
                          <IconOperation src={deleteIcon} />
                        </Operation>
                      </DivOperations>
                    </Item>
                  ))}
                </DivList>
                <BtnAdd onClick={handleOpenModalAddSubCategory}>
                  <IconBtnAdd src={addIcon} />
                  {t("add sub category")}
                </BtnAdd>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
