import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5.6vh 3.3vw;
`;

// -- start Header -- //

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3vh;
`;
export const DivIconHead = styled.div`
  height: 3.2vw;
  width: 3.2vw;
  background: ${({ deleteModal }) =>
    deleteModal ? "var(--red)" : "var(--orange)"};
  margin-inline-end: 0.6vw;
  border-radius: 50%;
`;
export const IconHead = styled.img`
  height: 100%;
  filter: brightness(0) invert(1);
`;

export const TitleHead = styled.p`
  color: black;
  font-size: 0.94vw;
  font-weight: 700;
`;

// -- end Header -- //

// -- start Body -- //

export const Body = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
`;
export const DivText = styled.div`
  width: 19.4vw;
  text-align: justify;
  font-size: 0.94vw;
  font-weight: 600;
`;
export const DeleteElement = styled.span`
  color: var(--red);
  font-weight: 700;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
`;
export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3vh;
`;
export const DivInput = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: column;
`;
export const DivMap = styled.div`
  width: 40.8vw;
  display: flex;
  flex-direction: column;
`;
export const MapBox = styled.div`
  max-width: 40.8vw;
  height: 48vh;
  font-size: 10px !important;
  background-color: var(--inputBackground);
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
  overflow: hidden;
`;

export const CoverImg = styled.div`
  height: 8.4vw;
  width: 8.4vw;
  border: solid 0.1vw var(--inputStroke);
  background: var(--inputBackground);
  border-radius: 1.3vw;
  padding: 0.3vw;
  margin: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;

export const Input = styled.input`
  padding: 0 1.2vw 0 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const Textarea = styled.textarea`
  padding: 1.8vh 1.2vw;
  font-size: 0.9vw;
  height: 13vh;
  width: 100%;
  background-color: var(--inputBackground);
  resize: none;
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
  &:focus {
    background: rgba(255, 255, 255, 0.4);
    color: black;
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: var(--placeholder);
  }
  &:-ms-input-placeholder {
    color: var(--placeholder);
  }
  &::placeholder {
    color: var(--placeholder);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance:   
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  padding-bottom: 1vh;
  padding-inline-start: 0.5vw;
`;
export const DivSelect = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 57.7vh;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6vh;
  flex-direction: column;
  overflow-x: hidden;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivSelectUser = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: row;
  column-gap: 0.7vw;
  align-items: flex-end;
`;
export const CoverImgUser = styled.div`
  height: 6vh;
  width: 6vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
`;
export const ImgUser = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1.6vh;
  ${({ haveAvatar }) => !haveAvatar && "filter: brightness(0) invert(1);"}
`;

export const SelectUser = styled.button`
  height: 6vh;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--selectBackground);
  color: white;
  font-size: 0.9vw;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const DivInputSearch = styled.div`
  width: 24.4vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputSearch = styled.input`
  padding-inline-start: 3.4vw;
  padding-inline-end: 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const SearchIcon = styled.img`
  position: absolute;
  z-index: 11;
  top: 0;
  marker-start: 0;
  padding-inline-start: 0.4vw;
  height: 100%;
`;

export const User = styled.button`
  width: 100%;
  min-height: 5.6vh;
  padding: 0.4vh 0.2vw;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: var(--userBackground);
  color: var(--brown);
  font-size: 0.9vw;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border-radius: 0.9vh;
  border: none;
  gap: 0.6vw;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CoverImgProfile = styled.div`
  height: 4.8vh;
  width: 4.8vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 0.8vh;
`;
export const ImgProfile = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.8vh;

  ${({ haveAvatar }) => !haveAvatar && "filter: brightness(0) invert(1);"}
`;
export const DivSelectTheme = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 18vh;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
  gap:0.6vw;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivTheme = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;
export const Theme = styled.img`
  height: 100%;
  cursor: pointer;
  border: 0.1vw solid var(--strokeTheme);
  border-radius: 0.8vh;
`;
export const BlurEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.8vh;
  box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.14vw);
  -webkit-backdrop-filter: blur(0.14vw);
  border: 0.2vw solid rgba(255, 255, 255, 0.3);
`;
export const Checked = styled.img`
  position: absolute;
  width: 70%;
`;

// -- end Body -- //

// -- start Button -- //

export const Btn = styled.button`
  width: ${({ large }) => (large ? "100%" : "46%;")};
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: ${({ okBtn }) => (okBtn ? "var(--orange)" : "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 1.5vh 0.3vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ okBtn }) =>
      okBtn ? "var(--darkOrange)" : "var(--darkRed)"};
    color: white;
  }
`;
export const BtnDiv = styled.div`
  ${({ btnPadding }) => btnPadding && "padding-top:1.8vh"};
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  width: 1.2vw;
  margin-left: 0.1vw;
  cursor: pointer;
  border-radius: 0.5vh;
  ${({ hover }) => hover && "&:hover {background-color: #3a7599;}"}
`;

// -- end Button -- //
