import {
  Container,
  Item,
  Img,
  CoverImg,
  Title,
  Description,
  Div,
  Time,
  Detail,
  DivFilters,
  Btnfilter,
  DivInputSearch,
  SearchIcon,
  StatusDiv,
  DivItems,
} from "./ElementCompaniesRequests";
import defaultLogo from "../../Images/headerIcons/deliveryCompanies.svg";
import searchIcon from "../../Images/search.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputSearch } from "../DeliveryCompanies/Modals/Element";
const List = ({ data, filter, filterStatus, handleSelectItem }) => {
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(4);
  const [activeCompany, setActiveCompany] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const handleSelectTab = (status) => {
    filterStatus(status);
    setActiveTab(status);
  };
  const handleSelectCompany = (item) => {
    handleSelectItem(item);
    setActiveCompany(item.id);
  };

  return (
    <>
      <Container>
        <DivFilters>
          <Btnfilter
            onClick={() => handleSelectTab(4)}
            active={activeTab === 4}
          >
            همه
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab(0)}
            active={activeTab === 0}
          >
            در انتظار تایید
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab(1)}
            active={activeTab === 1}
          >
            تایید شده ها
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab(2)}
            active={activeTab === 2}
          >
            رد شده ها
          </Btnfilter>
        </DivFilters>
        <DivInputSearch>
          <SearchIcon src={searchIcon} />
          <InputSearch
            placeholder="جستجو کنید ..."
            id="search"
            onChange={filter}
          />
        </DivInputSearch>
        <DivItems>
          {items.map((item) => (
            <Item
              active={item.id === activeCompany}
              onClick={() => handleSelectCompany(item)}
            >
              <CoverImg>
                <Img
                  src={item?.company?.logo ? item?.company?.logo : defaultLogo}
                />
              </CoverImg>
              <Detail>
                <Div>
                  <Title active={item.id === activeCompany}>
                    {item?.company?.title}
                  </Title>
                  <Time
                    status={
                      (item?.status === 0 &&
                        item.id !== activeCompany &&
                        "PENDING") ||
                      (item?.status === 0 &&
                        item.id === activeCompany &&
                        "ACTIVE_PENDING") ||
                      (item?.status === 1 && "AGREE") ||
                      (item?.status === 2 && "DENY") ||
                      (item?.status === 3 && "INVALID")
                    }
                  >
                    {item?.jalali_register_at}
                    {item?.status !== 0 && (
                      <StatusDiv
                        status={
                          (item?.status === 1 && "AGREE") ||
                          (item?.status === 2 && "DENY") ||
                          (item?.status === 3 && "INVALID")
                        }
                      >
                        <Img
                          src={
                            (item?.status === 1 && agreeIcon) ||
                            (item?.status === 2 && denyIcon) ||
                            (item?.status === 3 && invalidIcon)
                          }
                        />
                      </StatusDiv>
                    )}
                  </Time>
                </Div>
                <Description active={item.id === activeCompany}>
                  {item?.product?.title}
                </Description>
              </Detail>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default List;
