import {
  Container,
  Item,
  Img,
  Card,
  Btn,
  Icon,
  DetailItem,
  CoverImg,
  Title,
  IconDetail,
  BtnAdd,
  IconBtnAdd,
  Detail,
  Branches,
  DivBranches,
  DivAdmin,
  Admin,
} from "./Element";
import card from "../../Images/cards/tradeUnions.svg";
import card_ltr from "../../Images/cards/tradeUnions_ltr.svg";
import setting from "../../Images/setting.svg";
import addressIcon from "../../Images/addressIcon.svg";
import adminIcon from "../../Images/adminIcon.svg";
import defaultImg from "../../Images/defualtImage.svg";

import icon from "../../Images/addIcon.svg";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const List = ({ data, handleOpenModal }) => {
  const [items, setItems] = useState([]);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <>
      <Container>
        {items.map((item) => (
          <Item>
            <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
            <CoverImg>
              <Img src={item.logo ? item.logo : defaultImg} />
            </CoverImg>
            <DetailItem>
              <Title>{item.title}</Title>
              <Detail>
                <DivBranches>
                  <IconDetail src={addressIcon} />
                  <Branches>{item.branches?.length} شعبه </Branches>
                </DivBranches>
                <DivAdmin>
                  <IconDetail src={adminIcon} />
                  <Admin empty={!item?.user?.name && !item?.user?.family}>
                    {item?.user?.name && item?.user?.family
                      ? item?.user?.name + " " + item?.user?.family
                      : "ــ بدون نام ــ"}
                  </Admin>
                </DivAdmin>
              </Detail>
            </DetailItem>
            <Btn onClick={() => handleOpenModal("modalOperations", item)}>
              <Icon src={setting} />
            </Btn>
          </Item>
        ))}
        <BtnAdd onClick={() => handleOpenModal("modalAdd")}>
          <IconBtnAdd src={icon} />
          {t("add tradeUnions")}
        </BtnAdd>
      </Container>
    </>
  );
};

export default List;
