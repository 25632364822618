import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivIconHead,
  IconHead,
  DivSelect,
  User,
  CoverImgProfile,
  ImgProfile,
  InputSearch,
  DivInputSearch,
  SearchIcon,
  DivRow,
  DivColumn,
  DivInput,
  Lable,
  BtnDiv,
  Btn,
  ListItems,
  DivList,
  DivPermission,
  DivPermissions,
  DivBoxSubPermissions,
  DashedSubPermissions,
  DivSubPermissions,
  DivSubPermission,
} from "./Element";
import icon from "../../../Images/headerIcons/roles.svg";
import searchIcon from "../../../Images/search.svg";

import iconUser from "../../../Images/usernameIcon.svg";
import { useTranslation } from "react-i18next";

const SelectRole = ({
  showModalSelectRole,
  handleCloseModal,
  handleOpenModal,
  handleChoose,
  defaultRole,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalSelectRole);
    async function fetchPermissions() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data: rolesData } = await axiosConfig.get("/role/list", config);
        let rolesArray = [];
        rolesData.data.data.forEach((element) => {
          if (element.rank === 20) {
            rolesArray.push(element);
          }
        });
        setRoles(rolesArray);
        setSearchItems(rolesArray);
      } catch (error) {
        console.log("Error fetching permissions:", error);
      }
    }
    fetchPermissions();
    if (defaultRole) {
      setRole(defaultRole);
    } else {
      setRole([]);
    }
  }, [defaultRole, showModalSelectRole]);

  const CloseModal = () => {
    handleCloseModal("modalSelectRole");
    if (defaultRole) {
      handleOpenModal("addModal", defaultRole);
    } else {
      handleOpenModal("addModal", "defaultRole");
    }
  };
  const handleSelectRole = (item) => {
    setRole(item);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.persian_title
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setRoles(results);
    } else {
      setRoles(searchItems);
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("choose role")}</TitleHead>
          </Header>
          <Body>
            <DivRow>
              <DivColumn>
                <DivInputSearch>
                  <SearchIcon src={searchIcon} />
                  <InputSearch
                    placeholder="جستجوی نقش ..."
                    id="search"
                    onChange={filter}
                  />
                </DivInputSearch>
                <DivSelect>
                  {roles.map((item) => (
                    <User
                      active={role.id === item.id}
                      onClick={() => {
                        handleSelectRole(item);
                      }}
                    >
                      {item.persian_title}
                    </User>
                  ))}
                </DivSelect>
              </DivColumn>
              <DivColumn>
                <DivInput>
                  <Lable>{t("permissions")}</Lable>
                  <ListItems>
                    <DivList>
                      {role?.permissions?.map(
                        (permission) =>
                          !permission.parent && (
                            <DivPermissions key={permission?.id}>
                              <DivPermission>
                                {permission?.persian_title}
                              </DivPermission>
                              <DivBoxSubPermissions>
                                <DashedSubPermissions />
                                <DivSubPermissions>
                                  {role?.permissions?.map(
                                    (subPermission) =>
                                      subPermission?.parent ===
                                        permission.title && (
                                        <DivSubPermission
                                          key={subPermission?.id}
                                        >
                                          {subPermission?.persian_title}
                                        </DivSubPermission>
                                      )
                                  )}
                                </DivSubPermissions>
                              </DivBoxSubPermissions>
                            </DivPermissions>
                          )
                      )}
                    </DivList>
                  </ListItems>
                </DivInput>
                <BtnDiv btnPadding={true}>
                  <Btn
                    okBtn
                    onClick={() => {
                      handleCloseModal("modalSelectRole");
                      handleOpenModal("addModal", role);
                    }}
                  >
                    {t("choose")}
                  </Btn>
                  <Btn onClick={CloseModal}>{t("cancel")}</Btn>
                </BtnDiv>
              </DivColumn>
            </DivRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default SelectRole;
