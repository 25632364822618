import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./Pages";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";

import Categories from "./Pages/Categories";
import ListCategories from "./Pages/Categories/List";

import Products from "./Pages/Products";
import ListProducts from "./Pages/Products/List";
import CompaniesRequestsProducts from "./Pages/Products/CompaniesRequests";

import Invoices from "./Pages/Invoices";
import ListInvoices from "./Pages/Invoices/List";
import ListStatuses from "./Pages/Invoices/Statuses";

import TradeUnions from "./Pages/TradeUnions";
import ListTradeUnions from "./Pages/TradeUnions/List";

import DeliveryCompanies from "./Pages/DeliveryCompanies";
import ListDeliveryCompanies from "./Pages/DeliveryCompanies/List";

import Roles from "./Pages/Roles";
import ListRoles from "./Pages/Roles/List";

import Users from "./Pages/Users";
import RoysaUsers from "./Pages/Users/Roysa";
import CompaniesUsers from "./Pages/Users/Companies";
import TradeUnitsUsers from "./Pages/Users/TradeUnits";

import Comments from "./Pages/Comments";
import ListComments from "./Pages/Comments/List";

import Sliders from "./Pages/Sliders";
import ListSliders from "./Pages/Sliders/List";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (localStorage.getItem("tokenPanelRoysa") === null) {
      localStorage.setItem("tokenPanelRoysa", "");
    }
  });

  window.onbeforeunload = function () {
    window.setTimeout(function () {
      window.location = "/";
    }, 0);
    window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser
  };
  setInterval(function () {
    localStorage.setItem("tokenPanelRoysa", "");
    window.location.replace("/login");
  }, 86400000);

  const token = localStorage.getItem("tokenPanelRoysa");
  console.log(
    token === "" || localStorage.length === 0 ? "login" : "dashboard"
  );

  const redirectPath =
    token === "" || localStorage.length === 0 ? "login" : "dashboard";
  return (
    <Router className="App">
      <Routes>
        <Route path="login" element={<Login />} exact />
        <Route path="dashboard" element={<Dashboard />} exact />
        <Route path="rawMaterial" element={<HomePage />} exact>
          <Route path="categories" element={<Categories />} exact>
            <Route path="list" element={<ListCategories />} exact />
          </Route>
          <Route path="products" element={<Products />} exact>
            <Route path="list" element={<ListProducts />} exact />
            <Route
              path="companiesRequests"
              element={<CompaniesRequestsProducts />}
              exact
            />
          </Route>
          <Route path="invoices" element={<Invoices />} exact>
            <Route path="list" element={<ListInvoices />} exact />
            <Route path="statuses" element={<ListStatuses />} exact />
          </Route>
          <Route path="tradeUnions" element={<TradeUnions />} exact>
            <Route path="list" element={<ListTradeUnions />} exact />
          </Route>
          <Route path="deliveryCompanies" element={<DeliveryCompanies />} exact>
            <Route path="list" element={<ListDeliveryCompanies />} exact />
          </Route>
          <Route path="roles" element={<Roles />} exact>
            <Route path="list" element={<ListRoles />} exact />
          </Route>
          <Route path="users" element={<Users />} exact>
            <Route path="roysa" element={<RoysaUsers />} exact />
            <Route path="companies" element={<CompaniesUsers />} exact />
            <Route path="tradeUnits" element={<TradeUnitsUsers />} exact />
          </Route>
          <Route path="comments" element={<Comments />} exact>
            <Route path="list" element={<ListComments />} exact />
          </Route>
          <Route path="sliders" element={<Sliders />} exact>
            <Route path="list" element={<ListSliders />} exact />
          </Route>
        </Route>
        <Route path="/" element={<Navigate to={redirectPath} />} exact></Route>
        <Route path="*" element={<Navigate to={redirectPath} />} exact></Route>
      </Routes>
    </Router>
  );
}

export default App;
