import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  CoverImg,
  Img,
  Lable,
} from "./Element";
import icon from "../../../Images/headerIcons/categories.svg";
import iconSelect from "../../../Images/selectImg/category.svg";
import { useTranslation } from "react-i18next";

const Add = ({
  showAddModal,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
}) => {
  const [data, setData] = useState({
    title: "",
    image: null,
    parent_id: null,
    status: 1,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showAddModal);
  }, [showAddModal]);

  const schema = Joi.object({
    image: Joi.string().optional(),
    title: Joi.string().optional(),
    parent_id: [Joi.number().integer().required(), Joi.allow(null)],
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const CloseModal = () => {
    handleCloseModal("modalAdd");
    setData({
      title: "",
      image: null,
      parent_id: null,
      status: 1,
    });
    setErrors({});
  };

  const handleChange = (e) => {
    console.log(e.currentTarget.value);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      setDisabledBtn(true);
      doSubmit();
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "image") {
      errorMessage = "تصویر نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const selectFile = () => {
    document.getElementById("fname").click();
  };
  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        // const base64Image = e.target.result.replace(
        //   "data:image/svg+xml;",
        //   "data:image/svg;"
        // );
        setData({ ...data, image: base64Image });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
      console.log(data);
    }
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("creating categry ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post("/category/store", data, config);
      isOkResponse = true;
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("create new category")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <CoverImg
                haveImg={data.image ? true : false}
                onClick={selectFile}
              >
                <Img src={data.image ? data.image : iconSelect} />
              </CoverImg>
              <input
                hidden
                type="file"
                id="fname"
                name="fname"
                onChange={onSelectImage}
              ></input>
            </DivInput>

            <DivInput>
              <Lable>{t("category title")}</Lable>
              <Input id="title" onChange={handleChange} value={data.title} />
            </DivInput>

            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                {t("create")}
              </Btn>
              <Btn onClick={CloseModal}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
