import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Lable,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  DivRow,
  CoverImg,
  Img,
  DivInputPassword,
  InputPassword,
  BtnShowPassword,
  ImgShowPassword,
} from "./Element";
import icon from "../../../Images/defaultAvatar.svg";
import iconSelect from "../../../Images/selectImg/user.svg";
import Eye_slash from "../../../Images/Eye_slash.svg";
import Eye from "../../../Images/Eye.svg";

import { useTranslation } from "react-i18next";
const Add = ({
  showAddModal,
  handleAddNew,
  handleOpenModal,
  handleCloseModal,
}) => {
  const [data, setData] = useState({
    name: "",
    family: "",
    mobile: "",
    avatar: "",
    email: "",
    username: "",
    password: "",
    city_id: 1,
    work_phone: "",
    work_address: "",
    roles: ["Super Admin Delivery Company Roysa"],
    status: 1,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [imgShowPassword, setImgShowPassword] = useState(Eye_slash);
  const [imgShowPassword2, setImgShowPassword2] = useState(Eye_slash);
  const [validatePassword, setValidatePassword] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [titleRole, setTitleRole] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showAddModal);
    setErrors({});
  }, [showAddModal]);

  const schema = Joi.object({
    name: Joi.string().required(),
    family: Joi.string().required(),
    mobile: Joi.string().required(),
    avatar: [Joi.string().optional(), Joi.allow(null)],
    email: [Joi.string().optional(), Joi.allow(null)],
    username: Joi.string().required(),
    password: Joi.string().required(),
    city_id: [Joi.number().integer().required(), Joi.allow(null)],
    work_phone: [Joi.string().optional(), Joi.allow(null)],
    work_address: [Joi.string().optional(), Joi.allow(null)],
    roles: Joi.array().min(0).required(),
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const CloseModal = () => {
    handleCloseModal("addModal");
  };
  const closeAddModal = () => {
    handleCloseModal("addModal");
    setImgShowPassword(Eye_slash);
    setImgShowPassword2(Eye_slash);
    setValidatePassword("");
  };
  const handleChange = (e) => {
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleChangeValidatePassword = (e) => {
    setValidatePassword(e.currentTarget.value);
  };
  const handleShowPassword = () => {
    if (imgShowPassword === Eye_slash) {
      setImgShowPassword(Eye);
    } else {
      setImgShowPassword(Eye_slash);
    }
    const passwordInput = document.getElementById("password");
    const type =
      passwordInput.getAttribute("type") === "password" ? "text" : "password";
    passwordInput.setAttribute("type", type);
  };
  const handleShowPassword2 = () => {
    if (imgShowPassword2 === Eye_slash) {
      setImgShowPassword2(Eye);
    } else {
      setImgShowPassword2(Eye_slash);
    }
    const passwordInput = document.getElementById("password2");
    const type =
      passwordInput.getAttribute("type") === "password" ? "text" : "password";
    passwordInput.setAttribute("type", type);
  };
  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      if (validatePassword === "") {
        toast.warning("تکرار رمز عبور نمی تواند خالی باشد");
      } else if (validatePassword !== data.password) {
        toast.warning("رمز عبور و تکرار آن یکسان نیست");
      } else {
        setDisabledBtn(true);
        doSubmit();
      }
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "name") {
      errorMessage = "نام نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "family") {
      errorMessage = "نام خانوادگی نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "mobile") {
      errorMessage = "شماره موبایل نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "username") {
      errorMessage = "نام کاربری نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "password") {
      errorMessage = "رمز عبور نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const selectFile = () => {
    document.getElementById("fname").click();
  };
  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setData({ ...data, avatar: base64Image });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
      console.log(data);
    }
  };
  const handleNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading("در حال ایجاد کاربر ...");
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axiosConfig.post("/user/store", data, config);
      if (response.data.success) {
        isOkResponse = true;
      } else {
        if (response.data.message === "نام کاربری تکراری می باشد") {
          toast.update(toastId, {
            render: "نام کاربری تکراری می باشد",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        } else {
          toast.update(toastId, {
            render: "خطا در اتصال به سرور",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        }
      }
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      console.log(errors);
      toast.update(toastId, {
        render: "خطا در اتصال به سرور",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
      setImgShowPassword(Eye_slash);
      setImgShowPassword2(Eye_slash);
      setValidatePassword("");
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={closeAddModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>تعریف کاربر جدید</TitleHead>
          </Header>
          <Body>
            <DivRow>
              <DivInput>
                <CoverImg onClick={selectFile}>
                  <Img src={data?.avatar ? data?.avatar : iconSelect} />
                </CoverImg>
                <input
                  hidden
                  type="file"
                  id="fname"
                  name="fname"
                  onChange={onSelectImage}
                ></input>
              </DivInput>
              <DivInput>
                <Lable>نام</Lable>
                <Input id="name" onChange={handleChange} value={data.name} />
              </DivInput>
              <DivInput>
                <Lable>نام خانوادگی</Lable>
                <Input
                  id="family"
                  onChange={handleChange}
                  value={data.family}
                />
              </DivInput>
            </DivRow>
            <DivRow>
              <DivInput>
                <Lable>شماره موبایل</Lable>
                <Input
                  id="mobile"
                  onChange={handleChange}
                  onKeyPress={handleNumber}
                  value={data.mobile}
                  maxLength={11}
                />
              </DivInput>
              <DivInput>
                <Lable>ایمیل</Lable>
                <Input
                  id="email"
                  type="email"
                  onChange={handleChange}
                  value={data.email}
                />
              </DivInput>
              <DivInput>
                <Lable>نام کاربری</Lable>
                <Input
                  id="username"
                  onChange={handleChange}
                  value={data.username}
                />
              </DivInput>
            </DivRow>
            <DivRow>
              <DivInput>
                <Lable>رمز عبور</Lable>
                <DivInputPassword>
                  <InputPassword
                    type="password"
                    id="password"
                    value={data.password}
                    onChange={handleChange}
                  />
                  <BtnShowPassword onClick={handleShowPassword} okBtn>
                    <ImgShowPassword src={imgShowPassword} />
                  </BtnShowPassword>
                </DivInputPassword>
              </DivInput>
              <DivInput>
                <Lable>تکرار رمز عبور</Lable>
                <DivInputPassword>
                  <InputPassword
                    type="password"
                    id="password2"
                    value={validatePassword}
                    onChange={handleChangeValidatePassword}
                  />
                  <BtnShowPassword onClick={handleShowPassword2} okBtn>
                    <ImgShowPassword src={imgShowPassword2} />
                  </BtnShowPassword>
                </DivInputPassword>
              </DivInput>
            </DivRow>
            <DivRow>
              <DivInput></DivInput>
              <DivInput></DivInput>
              <BtnDiv btnPadding={true}>
                <Btn okBtn onClick={handleSubmit}>
                  ایجاد
                </Btn>
                <Btn onClick={closeAddModal}>انصراف</Btn>
              </BtnDiv>
            </DivRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
