import { useEffect, useState } from "react";
import Modal from "react-modal";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Detail,
  DivBranches,
  Admin,
  Branches,
  DivAdmin,
  IconDetail,
  Item,
  DivItemTitle,
  DivItemAddress,
  DivOperations,
  Icon,
  ItemTitle,
  Operation,
  IconOperation,
  BtnAdd,
  IconBtnAdd,
  DivList,
  IconOperationHead,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/tradeUnions.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";
import addIcon from "../../../Images/addIcon.svg";
import addressIcon from "../../../Images/addressIcon.svg";
import adminIcon from "../../../Images/adminIcon.svg";

import { useTranslation } from "react-i18next";
// import AddRoleForm from "../../Form/AddRoleForm";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  handleResetUpdateModal,
  handleResetUpdateBranchModal,
  detailItem,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log(detailItem);
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleOpenModalAddBranch = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalAddBranch", detailItem);
  };
  const handleUpdate = () => {
    handleResetUpdateModal();
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleOpenModalUpdateBranch = (detailBranch) => {
    handleResetUpdateBranchModal();
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdateBranch", detailItem, detailBranch);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete");
  };
  const handleOpenModalDeleteBranch = (detailBranch) => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDeleteBranch", detailItem, detailBranch);
  };
  return (
    <>
      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.logo ? true : false}
                  src={item?.logo ? item?.logo : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn onClick={handleUpdate} operation="EDIT">
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <Detail>
              <DivBranches>
                <IconDetail src={addressIcon} />
                <Branches>{item?.branches?.length} شعبه </Branches>
              </DivBranches>
              <DivAdmin>
                <IconDetail src={adminIcon} />
                <Admin empty={!item?.user?.name && !item?.user?.family}>
                  {item?.user?.name && item?.user?.family
                    ? item?.user?.name + " " + item?.user?.family
                    : "ــ بدون نام ــ"}
                </Admin>
              </DivAdmin>
            </Detail>
            <DivItems>
              <Lable>شعبه های واحد صنفی</Lable>
              <ListItems>
                <DivList>
                  {item?.branches?.map((branch) => (
                    <Item>
                      <DivItemTitle>
                        <Icon src={addressIcon} />
                        <ItemTitle>{branch.title}</ItemTitle>
                      </DivItemTitle>
                      <DivItemAddress>{branch.address}</DivItemAddress>
                      <DivOperations>
                        <Operation
                          operation="EDIT"
                          onClick={() => handleOpenModalUpdateBranch(branch)}
                        >
                          <IconOperation src={editIcon} />
                        </Operation>
                        <Operation
                          operation="DELETE"
                          onClick={() => handleOpenModalDeleteBranch(branch)}
                        >
                          <IconOperation src={deleteIcon} />
                        </Operation>
                      </DivOperations>
                    </Item>
                  ))}
                </DivList>
                <BtnAdd onClick={handleOpenModalAddBranch}>
                  <IconBtnAdd src={addIcon} />
                  {t("add branch")}
                </BtnAdd>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
