import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Detail,
  Admin,
  DivAdmin,
  IconDetail,
  DivList,
  IconOperationHead,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/deliveryCompanies.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";
import adminIcon from "../../../Images/adminIcon.svg";
import acceptSliderIcon from "../../../Images/acceptSlider.svg";
import acceptProductIcon from "../../../Images/acceptProduct.svg";

import { useTranslation } from "react-i18next";
import {
  ActivCategoryCheckbox,
  DashedSubCategories,
  DivBoxSubCategories,
  DivCategories,
  DivCategory,
  DivSubCategories,
  DivSubCategory,
  ImgCategory,
} from "./Element";
// import AddRoleForm from "../../Form/AddRoleForm";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
  handleResetUpdateModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    console.log(detailItem);
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleUpdate = () => {
    handleResetUpdateModal();
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete", detailItem);
  };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleConfirmedPrice = (item) => {
    if (item?.confirmed_price_type === "regular") {
      return numberWithCommas(item?.confirmed_price) + " تومان";
    } else {
      return item?.confirmed_price_percent + " درصد";
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.logo ? true : false}
                  src={item?.logo ? item?.logo : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn operation="EDIT" onClick={handleUpdate}>
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <Detail>
              <DivAdmin>
                <IconDetail src={adminIcon} />
                <Admin>{item?.user?.name + " " + item?.user?.family}</Admin>
              </DivAdmin>
              {item?.automatic_confirmation_slider && (
                <DivAdmin>
                  <IconDetail src={acceptSliderIcon} />
                  <Admin>تایید خودکار اسلایدر</Admin>
                </DivAdmin>
              )}
              {item?.automatic_confirmation_product && (
                <DivAdmin>
                  <IconDetail src={acceptProductIcon} />
                  <Admin>
                    {"تایید خودکار اطلاعات محصول ( سود رویسا : " +
                      handleConfirmedPrice(item) +
                      " )"}{" "}
                  </Admin>
                </DivAdmin>
              )}
            </Detail>
            <DivItems>
              <Lable>دسته های کالایی مجاز</Lable>
              <ListItems>
                <DivList>
                  {item?.categories?.map(
                    (category) =>
                      !category.parent && (
                        <DivCategories key={category?.id}>
                          <DivCategory>
                            <ActivCategoryCheckbox active>
                              <ImgCategory src={category?.image} />
                            </ActivCategoryCheckbox>
                            {category?.title}
                          </DivCategory>
                          <DivBoxSubCategories>
                            <DashedSubCategories />
                            <DivSubCategories>
                              {item?.categories?.map(
                                (subCategory) =>
                                  subCategory?.parent?.id === category.id && (
                                    <DivSubCategory key={subCategory?.id}>
                                      {subCategory?.title}
                                    </DivSubCategory>
                                  )
                              )}
                            </DivSubCategories>
                          </DivBoxSubCategories>
                        </DivCategories>
                      )
                  )}
                </DivList>
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
