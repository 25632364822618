import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import ColorPicker from "react-pick-color";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  Lable,
  DivColorPicker,
} from "./Element";
import icon from "../../../Images/headerIcons/status.svg";
import { useTranslation } from "react-i18next";

const Add = ({
  showAddModal,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
}) => {
  const [data, setData] = useState({
    title: "",
    color: "#f6b131",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showAddModal);
  }, [showAddModal]);

  const schema = Joi.object({
    title: Joi.string().required(),
    color: Joi.string().optional(),
  });
  const CloseModal = () => {
    handleCloseModal("modalAdd");
    setData({
      title: "",
      color: "#f6b131",
    });
    setErrors({});
  };

  const handleChangeColor = (color) => {
    setData((prevData) => ({
      ...prevData,
      color,
    }));
  };
  const handleChange = (e) => {
    console.log(e.currentTarget.value);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      setDisabledBtn(true);
      doSubmit();
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("creating status ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post("/status/store", data, config);
      isOkResponse = true;
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead color={"var(--orange)"}>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("create new status")}</TitleHead>
          </Header>
          <Body>
            <DivInput>
              <Lable>{t("status title")}</Lable>
              <Input id="title" onChange={handleChange} value={data.title} />
            </DivInput>
            <DivColorPicker>
              <Lable>{t("انتخاب رنگ")}</Lable>

              <ColorPicker
                className="colorPicker"
                color={data.color}
                onChange={(color) => handleChangeColor(color.hex)}
                theme={{
                  borderRadius: "1.6vh",
                  width: "19.4vw",
                  boxShadow: "none",
                  borderColor: "#E4DACC",
                }}
              />
            </DivColorPicker>
            <BtnDiv btnPadding={true}>
              <Btn okBtn onClick={handleSubmit}>
                {t("create")}
              </Btn>
              <Btn onClick={CloseModal}>{t("cancel")}</Btn>
            </BtnDiv>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
