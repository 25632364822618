import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5.6vh 3.3vw;
`;

// -- start Header -- //

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3vh;
`;
export const DivIconHead = styled.div`
  height: 3.2vw;
  width: 3.2vw;
  background: ${({ deleteModal }) =>
    deleteModal ? "var(--red)" : "var(--orange)"};
  margin-inline-end: 0.6vw;
  border-radius: 50%;
`;
export const IconHead = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
  ${({ isLogo }) =>
    isLogo ? "padding:0.5vw" : "filter: brightness(0) invert(1);"}
`;

export const TitleHead = styled.p`
  color: black;
  font-size: 0.94vw;
  font-weight: 700;
`;

// -- end Header -- //

// -- start Body -- //

export const Body = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
`;
export const DivText = styled.div`
  width: 19.4vw;
  text-align: justify;
  font-size: 0.94vw;
  font-weight: 600;
`;
export const DeleteElement = styled.span`
  color: var(--red);
  font-weight: 700;
`;
export const DivInput = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  height: 8.4vw;
  width: 8.4vw;
  border-radius: 1.3vw;
  padding: 0.5vw;
  margin: auto;
  cursor: pointer;
  ${({ haveImg }) =>
    haveImg
      ? "background: var(--orange);"
      : "background: var(--inputBackground);border: solid 0.1vw var(--inputStroke);"};
  &:hover {
    opacity: 0.7;
  }
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;

export const Input = styled.input`
  padding: 0 1.2vw 0 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  padding-bottom: 1vh;
  padding-inline-start: 0.5vw;
`;
export const DivSelect = styled.div`
  padding: 1vh;
  font-size: 0.9vw;
  height: 57.7vh;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3vw;
  flex-direction: column;
  overflow-x: hidden;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivSelectUser = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: row;
  column-gap: 0.7vw;
`;
export const CoverImgUser = styled.div`
  height: 6vh;
  width: 6vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
`;
export const ImgUser = styled.img`
  width: 100%;
  height: 100%;
  filter: brightness(0) invert(1);
`;

export const SelectUser = styled.button`
  /* width: 100%; */
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--selectBackground);
  color: white;
  font-size: 0.9vw;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const DivInputSearch = styled.div`
  width: 24.4vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputSearch = styled.input`
  padding-inline-start: 3.4vw;
  padding-inline-end: 1.2vw;
  font-size: 0.9vw;
  height: 6vh;
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? "#f4f7f7" : "var(--inputBackground)"};
  color: ${({ disabled }) => (disabled ? "#B5B5B5" : "")};
  ${({ disabled }) => disabled && "cursor: not-allowed;"}
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
`;
export const SearchIcon = styled.img`
  position: absolute;
  z-index: 11;
  top: 0;
  marker-start: 0;
  padding-inline-start: 0.4vw;
  height: 100%;
`;

export const User = styled.button`
  /* width: 100%; */
  width: 100%;
  min-height: 5.6vh;
  padding: 0.4vh 0.2vw;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: var(--userBackground);
  color: var(--brown);
  font-size: 0.9vw;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border-radius: 0.9vh;
  border: none;
  gap: 0.6vw;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CoverImgProfile = styled.div`
  height: 4.8vh;
  width: 4.8vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 0.8vh;
`;
export const ImgProfile = styled.img`
  width: 100%;
  height: 100%;
  filter: brightness(0) invert(1);
`;

// -- end Body -- //

// -- start Button -- //

export const Btn = styled.button`
  width: ${({ large }) => (large ? "100%" : "46%;")};
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: ${({ okBtn }) => (okBtn ? "var(--orange)" : "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 1.5vh 0.3vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ okBtn }) =>
      okBtn ? "var(--darkOrange)" : "var(--darkRed)"};
    color: white;
  }
`;
export const BtnDiv = styled.div`
  ${({ btnPadding }) => btnPadding && "padding-top:1.8vh"};
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  width: 1.2vw;
  margin-left: 0.1vw;
  cursor: pointer;
  border-radius: 0.5vh;
  ${({ hover }) => hover && "&:hover {background-color: #3a7599;}"}
`;

// -- end Button -- //
