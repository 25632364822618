import {
  Container,
  Btn,
  DetailItem,
  Img,
  Unit,
  Header,
  DivIconHead,
  IconHead,
  TitleHead,
  Head,
  DivTime,
  CalendarTime,
  ClockTime,
  SeparatorTime,
  Body,
  DivDetail,
  Lable,
  DivDetailProduct,
  CoverImgProduct,
  ImgProduct,
  SeparatorDetailProduct,
  BtnDiv,
  DivDetailText,
  DetailText,
  Text,
  DivText,
  DivDetailRequest,
  DivImages,
  CoverImage,
  DivDescription,
  LableDetailRequest,
  DivPrice,
  DivWeight,
  BeforePrice,
  NowPrice,
  LableDetailRequestDescription,
  BeforeStock,
  NowStock,
  CoverArrow,
  TitleBtn,
  BtnIcon,
  Footer,
  ImgDefault,
  DivResponse,
  Span,
  DivEndHead,
  StatusDiv,
  DiscountDate,
} from "./ElementCompaniesRequests";
import icon from "../../Images/headerIcons/deliveryCompanies.svg";
import arrowPrice from "../../Images/arrowPrice.svg";
import acceptIcon from "../../Images/acceptIcon.svg";
import rejectIcon from "../../Images/rejectIcon.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";

import defaultBackground from "../../Images/defaultBackground.svg";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "jalali-moment";

const List = ({ detailItem, handleOpenModal }) => {
  const [item, setItem] = useState(null);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (detailItem) {
      console.log(detailItem);
      setItem(detailItem);
    }
  }, [detailItem]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getTime = (x) => {
    return x?.split(" | ")[0];
  };
  const getCalendar = (x) => {
    return x?.split(" | ")[1];
  };
  const handleDiscountDateTime = (discountStartAt, discountEndAt) => {
    const startAtTime = discountStartAt?.split(" ")[1];
    const endAtTime = discountEndAt?.split(" ")[1];
    discountStartAt = discountStartAt?.split(" ")[0];
    const startAtDate = moment(discountStartAt, "YYYY-MM-DD")
      .locale("fa")
      .format("jYYYY/jMM/jDD");
    discountEndAt = discountEndAt?.split(" ")[0];
    const endAtDate = moment(discountEndAt, "YYYY-MM-DD")
      .locale("fa")
      .format("jYYYY/jMM/jDD");

    return (
      " ( از " +
      startAtDate +
      " | " +
      startAtTime +
      " تا " +
      endAtDate +
      " | " +
      endAtTime +
      ")"
    );
  };

  return (
    <>
      <Container UnPaddingStart>
        <DetailItem>
          {!item ? (
            <ImgDefault src={defaultBackground} />
          ) : (
            <>
              <Header>
                <Head>
                  <DivIconHead>
                    <IconHead
                      isLogo={item?.company?.logo}
                      src={item?.company?.logo ? item?.company?.logo : icon}
                    />
                  </DivIconHead>
                  <TitleHead>{item?.company?.title}</TitleHead>
                </Head>
                <DivEndHead
                  status={
                    (item?.status === 0 && "PENDING") ||
                    (item?.status === 1 && "AGREE") ||
                    (item?.status === 2 && "DENY") ||
                    (item?.status === 3 && "INVALID")
                  }
                >
                  {((item?.status === 1 && "AGREE") ||
                    (item?.status === 2 && "DENY") ||
                    (item?.status === 3 && "INVALID")) && (
                    <>
                      <StatusDiv
                        status={
                          (item?.status === 1 && "AGREE") ||
                          (item?.status === 2 && "DENY") ||
                          (item?.status === 3 && "INVALID")
                        }
                      >
                        <Img
                          src={
                            (item?.status === 1 && agreeIcon) ||
                            (item?.status === 2 && denyIcon) ||
                            (item?.status === 3 && invalidIcon)
                          }
                        />
                      </StatusDiv>
                      {(item?.status === 1 && "تایید شده") ||
                        (item?.status === 2 && "رد شده") ||
                        (item?.status === 3 && "منقضی شده")}
                    </>
                  )}

                  <DivTime
                    status={
                      (item?.status === 0 && "PENDING") ||
                      (item?.status === 1 && "AGREE") ||
                      (item?.status === 2 && "DENY") ||
                      (item?.status === 3 && "INVALID")
                    }
                  >
                    <ClockTime>{getTime(item?.jalali_register_at)}</ClockTime>
                    <SeparatorTime
                      status={
                        (item?.status === 0 && "PENDING") ||
                        (item?.status === 1 && "AGREE") ||
                        (item?.status === 2 && "DENY") ||
                        (item?.status === 3 && "INVALID")
                      }
                    />
                    <CalendarTime>
                      {getCalendar(item?.jalali_register_at)}
                    </CalendarTime>
                  </DivTime>
                </DivEndHead>
              </Header>
              <Body>
                <DivDetail>
                  <DivDetailProduct>
                    <CoverImgProduct>
                      <ImgProduct src={item?.product?.image} />
                    </CoverImgProduct>
                    <DivDetailText>
                      <DetailText>
                        <SeparatorDetailProduct />
                        <DivText>
                          <Text>
                            <Lable> عنوان محصول :</Lable>
                            {item?.product?.title}
                          </Text>
                          <Text>
                            <Lable> دسته کالایی محصول :</Lable>
                            {item?.product?.category?.parent?.title}
                          </Text>
                        </DivText>
                      </DetailText>
                      <DetailText>
                        <SeparatorDetailProduct />
                        <DivText>
                          <Text>
                            <Lable> واحد :</Lable>
                            {item?.product?.measure_unit}
                          </Text>
                          <Text>
                            <Lable> زیر دسته کالایی محصول :</Lable>
                            {item?.product?.category?.title}
                          </Text>
                        </DivText>
                      </DetailText>
                    </DivDetailText>
                  </DivDetailProduct>
                </DivDetail>
                <DivDetail>
                  <Lable>جزئیات درخواست</Lable>
                  <DivDetailRequest>
                    {item?.company_product?.images?.length !== 0 && (
                      <DivImages>
                        {item?.company_product?.images.map((image) => (
                          <>
                            <CoverImage>
                              <Img src={image.image} />
                            </CoverImage>
                          </>
                        ))}
                      </DivImages>
                    )}
                    <DivDetailText>
                      <DivPrice>
                        <LableDetailRequest>قیمت :</LableDetailRequest>
                        <BeforePrice
                          before={item?.status === 1 || item?.status === 3}
                        >
                          {numberWithCommas(item?.price)}
                          <Unit
                            before={item?.status === 1 || item?.status === 3}
                          >
                            تومان
                          </Unit>
                        </BeforePrice>
                        {(item?.status === 1 || item?.status === 3) && (
                          <>
                            <CoverArrow>
                              <Img src={arrowPrice} />
                            </CoverArrow>
                            <NowPrice>
                              {numberWithCommas(item?.confirmed_price)}
                              <Unit>تومان</Unit>
                            </NowPrice>
                          </>
                        )}
                      </DivPrice>
                      <DivWeight>
                        <LableDetailRequest>موجودی :</LableDetailRequest>
                        {item?.status === 0 ? (
                          <BeforeStock>
                            {item?.weight}
                            <Unit>{item?.product?.measure_unit}</Unit>
                          </BeforeStock>
                        ) : (
                          <NowStock>
                            {item?.weight}
                            <Unit>{item?.product?.measure_unit}</Unit>
                          </NowStock>
                        )}
                      </DivWeight>
                    </DivDetailText>
                    <DivDetailText>
                      <DivWeight>
                        <LableDetailRequest>تخفیف :</LableDetailRequest>
                        {item?.discount_type !== "none" ? (
                          item?.status === 0 ? (
                            item?.discount_type === "percentage" ? (
                              <BeforeStock>
                                {item?.discount_value}
                                <Unit>درصد</Unit>
                                <DiscountDate>
                                  {handleDiscountDateTime(
                                    item?.discount_start_at,
                                    item?.discount_end_at
                                  )}
                                </DiscountDate>
                              </BeforeStock>
                            ) : (
                              <BeforeStock>
                                {item?.discount_value}
                                <Unit>تومان</Unit>
                                <DiscountDate>
                                  {handleDiscountDateTime(
                                    item?.discount_start_at,
                                    item?.discount_end_at
                                  )}
                                </DiscountDate>
                              </BeforeStock>
                            )
                          ) : item?.discount_type === "percentage" ? (
                            <NowStock>
                              {item?.discount_value}
                              <Unit>درصد</Unit>
                              <DiscountDate>
                                {handleDiscountDateTime(
                                  item?.discount_start_at,
                                  item?.discount_end_at
                                )}
                              </DiscountDate>
                            </NowStock>
                          ) : (
                            <NowStock>
                              {item?.discount_value}
                              <Unit>تومان</Unit>
                              <DiscountDate>
                                {handleDiscountDateTime(
                                  item?.discount_start_at,
                                  item?.discount_end_at
                                )}
                              </DiscountDate>
                            </NowStock>
                          )
                        ) : item?.status === 0 ? (
                          <BeforeStock>
                            ۰<Unit>تومان</Unit>
                          </BeforeStock>
                        ) : (
                          <NowStock>
                            ۰<Unit>تومان</Unit>
                          </NowStock>
                        )}
                      </DivWeight>
                    </DivDetailText>

                    <DivDescription>
                      <LableDetailRequestDescription>
                        توضیحات محصول :
                      </LableDetailRequestDescription>
                      {item?.description}
                    </DivDescription>
                  </DivDetailRequest>
                </DivDetail>
              </Body>
              <Footer>
                {(item?.status === 0 && (
                  <BtnDiv btnPadding={true}>
                    <Btn
                      operation="ACCEPT"
                      onClick={() => handleOpenModal("modalAccept", item)}
                    >
                      <BtnIcon src={acceptIcon} />
                      <TitleBtn>{t("accept")}</TitleBtn>
                    </Btn>
                    <Btn
                      operation="REJECT"
                      onClick={() => handleOpenModal("modalReject", item)}
                    >
                      <BtnIcon src={rejectIcon} />
                      <TitleBtn>{t("reject")}</TitleBtn>
                    </Btn>
                  </BtnDiv>
                )) ||
                  (item?.status === 1 && (
                    <DivResponse>
                      <Span>
                        {item?.confirmed_price_type === "regular"
                          ? numberWithCommas(
                              item?.confirmed_price - item?.price
                            ) + " تومان "
                          : item?.confirmed_price_percent + " درصد "}
                      </Span>
                      &nbsp; سود از فروش محصول متعلق به رویسا می باشد .
                    </DivResponse>
                  )) ||
                  (item?.status === 2 && (
                    <DivResponse reject>
                      <LableDetailRequest>علت رد :</LableDetailRequest>
                      &nbsp;
                      {item?.confirmed_description}
                    </DivResponse>
                  ))}
              </Footer>
            </>
          )}
        </DetailItem>
      </Container>
    </>
  );
};

export default List;
