import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BoxSelectItems,
  Header,
  Container,
  LogoOrganization,
  Footer,
  DivFooterTitle,
  FooterTitle,
  BoxDashboard,
  Profile,
  DivProfile,
  ProfileCover,
  ProfileImg,
  DivProfileInformation,
  UserName,
  UserPermission,
  Item,
  ImgItem,
  ShadeItem,
  ItemLink,
  IconItem,
  DivSelectItems,
} from "./DashboardElements";

import logo from "../../Images/logo.svg";

import digitalmenuImage from "../../Images/dashboard/jpg/digitalmenuimage.jpg";
import digitalmenuIcon from "../../Images/dashboard/svg/menuicon.svg";
import orderingiconImage from "../../Images/dashboard/jpg/orderingiconimage.jpg";
import orderingiconIcon from "../../Images/dashboard/svg/orderingicon.svg";
import accountingImage from "../../Images/dashboard/jpg/accountingimage.jpg";
import accountingIcon from "../../Images/dashboard/svg/accountingicon.svg";
import inventoryImage from "../../Images/dashboard/jpg/inventoryimage.jpg";
import inventoryIcon from "../../Images/dashboard/svg/inventoryicon.svg";
import rawmaterialImage from "../../Images/dashboard/jpg/rawmaterialimage.jpg";
import rawmaterialIcon from "../../Images/dashboard/svg/rawmaterialicon.svg";
import technicalservicesImage from "../../Images/dashboard/jpg/technicalservicesimage.jpg";
import technicalservicesIcon from "../../Images/dashboard/svg/technicalservicesicon.svg";
import advertisingImage from "../../Images/dashboard/jpg/advertisingimage.jpg";
import advertisingIcon from "../../Images/dashboard/svg/advertisingicon.svg";
import educationImage from "../../Images/dashboard/jpg/educationimage.jpg";
import educationIcon from "../../Images/dashboard/svg/educationicon.svg";
import manpowerImage from "../../Images/dashboard/jpg/manpowerimage.jpg";
import manpowerIcon from "../../Images/dashboard/svg/manpowericon.svg";
import assignmentImage from "../../Images/dashboard/jpg/assignmentimage.jpg";
import assignmentIcon from "../../Images/dashboard/svg/assignmenticon.svg";
import settingupImage from "../../Images/dashboard/jpg/settingupimage.jpg";
import settingupIcon from "../../Images/dashboard/svg/settingupicon.svg";
import charityImage from "../../Images/dashboard/jpg/charityimage.jpg";
import charityIcon from "../../Images/dashboard/svg/charityicon.svg";
import healthservicesImage from "../../Images/dashboard/jpg/healthservicesimage.jpg";
import healthservicesIcon from "../../Images/dashboard/svg/healthservicesicon.svg";
import insuranceservicesImage from "../../Images/dashboard/jpg/insuranceservicesimage.jpg";
import insuranceservicesIcon from "../../Images/dashboard/svg/insuranceservicesicon.svg";

import profileCover from "../../Images/profileCover.svg";
import profile from "../../Images/defaultAvatar.svg";

// import { useNavigate } from "react-router";

const Login = () => {
  // const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userPanelRoysa")));
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Container>
        <BoxDashboard>
          <Header>
            <Profile>
              <DivProfile>
                <ProfileCover src={profileCover} />
                <ProfileImg
                  defaultImg={!user?.avatar}
                  src={user?.avatar ? user.avatar : profile}
                />
              </DivProfile>
              <DivProfileInformation>
                <UserName>{user?.name + " " + user?.family}</UserName>
                <UserPermission>{user?.role_persian_title}</UserPermission>
              </DivProfileInformation>
            </Profile>
            <LogoOrganization src={logo} />
          </Header>
          <BoxSelectItems>
            <DivSelectItems>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={digitalmenuIcon} />
                    {t("digital menu")}
                  </ShadeItem>
                  <ImgItem src={digitalmenuImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={orderingiconIcon} />
                    {t("ordering")}
                  </ShadeItem>
                  <ImgItem src={orderingiconImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={accountingIcon} />
                    {t("accounting")}
                  </ShadeItem>
                  <ImgItem src={accountingImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={inventoryIcon} />
                    {t("inventory")}
                  </ShadeItem>
                  <ImgItem src={inventoryImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink to="/rawMaterial">
                  <ShadeItem>
                    <IconItem src={rawmaterialIcon} />
                    {t("raw material")}
                  </ShadeItem>
                  <ImgItem src={rawmaterialImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={technicalservicesIcon} />
                    {t("technical services")}
                  </ShadeItem>
                  <ImgItem src={technicalservicesImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={advertisingIcon} />
                    {t("ads of equipment")}
                  </ShadeItem>
                  <ImgItem src={advertisingImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={educationIcon} />
                    {t("educational")}
                  </ShadeItem>
                  <ImgItem src={educationImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={manpowerIcon} />
                    {t("manpower")}
                  </ShadeItem>
                  <ImgItem src={manpowerImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={assignmentIcon} />
                    {t("lease and assignment")}
                  </ShadeItem>
                  <ImgItem src={assignmentImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={settingupIcon} />
                    {t("setting up")}
                  </ShadeItem>
                  <ImgItem src={settingupImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={charityIcon} />
                    {t("charity")}
                  </ShadeItem>
                  <ImgItem src={charityImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={healthservicesIcon} />
                    {t("health services")}
                  </ShadeItem>
                  <ImgItem src={healthservicesImage} />
                </ItemLink>
              </Item>
              <Item>
                <ItemLink>
                  <ShadeItem>
                    <IconItem src={insuranceservicesIcon} />
                    {t("insurance services")}
                  </ShadeItem>
                  <ImgItem src={insuranceservicesImage} />
                </ItemLink>
              </Item>
            </DivSelectItems>
          </BoxSelectItems>
        </BoxDashboard>
        <Footer>
          <DivFooterTitle>
            <FooterTitle>Royal Services Application</FooterTitle>
          </DivFooterTitle>
        </Footer>
      </Container>
    </>
  );
};

export default Login;
