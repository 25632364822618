import React, { useEffect, useState } from "react";
import Search from "../../Components/Users/Search";
import List from "../../Components/Users/Companies";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import ModalAdd from "../../Components/Users/Modals/AddCompanyUser";
import ModalOperations from "../../Components/Users/Modals/Operations";
import ModalDelete from "../../Components/Users/Modals/DeleteCompanyUser";
import ModalUpdate from "../../Components/Users/Modals/UpdateCompanyUser";

import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

const Companies = () => {
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);

  const [searchItems, setSearchItems] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalOperations, setShowModalOperations] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleOpenModal = (modal, detailItem) => {
    if (modal === "addModal") {
      setShowModalAdd(true);
    } else if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(true);
      setDetailItem(detailItem);
    } else if (modal === "modalDelete") {
      setShowModalDelete(true);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "addModal") {
      setShowModalAdd(false);
    } else if (modal === "modalOperations") {
      setShowModalOperations(false);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(false);
    } else if (modal === "modalDelete") {
      setShowModalDelete(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/users/rank/10", config);
        const itemsData = data?.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching users:", error);
      }
    }

    fetchData();
  }, []);
  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/users/rank/10", config);
        const itemsData = data?.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "کاربر با موفقیت ثبت شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };
  const handleDelete = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/users/rank/10", config);
        const itemsData = data?.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "کاربر با موفقیت حذف شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };
  const handleUpdate = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/users/rank/10", config);
        const itemsData = data?.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "کاربر با موفقیت ویرایش شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        let nameFamily = item?.name + " " + item?.family;
        return (
          nameFamily.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.family.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List handleOpenModal={handleOpenModal} data={items} />
          )}
        </ListWrapper>
        <ModalAdd
          showAddModal={showModalAdd}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleAddNew={handleAddNew}
        ></ModalAdd>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
        ></ModalOperations>
        <ModalDelete
          showModalDelete={showModalDelete}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        ></ModalDelete>
        <ModalUpdate
          detailItem={detailItem}
          showModalUpdate={showModalUpdate}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
        ></ModalUpdate>
      </Container>
    </>
  );
};

export default Companies;
