import React, { Component, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import Search from "../../Components/Invoices/SearchStatus";
import List from "../../Components/Invoices/Statuses";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import ModalAdd from "../../Components/Invoices/Modals/AddStatus";
import ModalUpdate from "../../Components/Invoices/Modals/UpdateStatus";
import ModalDelete from "../../Components/Invoices/Modals/DeleteStatus";

import ModalOperations from "../../Components/Invoices/Modals/OperationsStatus";

const Categories = () => {
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);

  const [searchItems, setSearchItems] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showModalOperations, setShowModalOperations] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleOpenModal = (modal, detailItem, detailSubItem) => {
    if (modal === "modalAdd") {
      setShowModalAdd(true);
    } else if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
      console.log(detailItem);
    } else if (modal === "modalUpdate") {
      setDetailItem(detailItem);
      setShowModalUpdate(true);
    } else if (modal === "modalDelete") {
      setDetailItem(detailItem);
      setShowModalDelete(true);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalAdd") {
      setShowModalAdd(false);
    } else if (modal === "modalOperations") {
      setShowModalOperations(false);
    } else if (modal === "modalDelete") {
      setShowModalDelete(false);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/status/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchData();
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.title.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };
  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/status/list", config);
        const itemsData = data.data;
        console.log(itemsData);
        console.log(data);

        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "وضعیت با موفقیت ثبت شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };
  const handleDelete = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/status/list", config);
        const itemsData = data.data;
        console.log(itemsData);
        console.log(data);

        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "وضعیت با موفقیت حذف شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const handleUpdate = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/status/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "وضعیت با موفقیت ویرایش شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List handleOpenModal={handleOpenModal} data={items} />
          )}
        </ListWrapper>
        <ModalAdd
          showAddModal={showModalAdd}
          handleCloseModal={handleCloseModal}
          handleAddNew={handleAddNew}
        ></ModalAdd>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
        ></ModalOperations>
        <ModalUpdate
          detailItem={detailItem}
          showUpdateModal={showModalUpdate}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
        ></ModalUpdate>
        <ModalDelete
          showModalDelete={showModalDelete}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        ></ModalDelete>
      </Container>
    </>
  );
};

export default Categories;
