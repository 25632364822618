import React, { Component, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import Search from "../../Components/Categories/Search";
import List from "../../Components/Categories/List";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import ModalAdd from "../../Components/Categories/Modals/Add";
import ModalUpdate from "../../Components/Categories/Modals/Update";
import ModalDelete from "../../Components/Categories/Modals/Delete";

import ModalOperations from "../../Components/Categories/Modals/Operations";
import ModalAddSubCategory from "../../Components/Categories/Modals/AddSubCategory";
import ModalUpdateSubCategory from "../../Components/Categories/Modals/UpdateSubCategory";
import ModalDeleteSubCategory from "../../Components/Categories/Modals/DeleteSubCategory";

const Categories = () => {
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [detailSubItem, setDetailSubItem] = useState([]);

  const [searchItems, setSearchItems] = useState([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showModalOperations, setShowModalOperations] = useState(false);
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false);
  const [showDeleteSubCategoryModal, setShowDeleteSubCategoryModal] =
    useState(false);

  const [showUpdateSubCategoryModal, setShowUpdateSubCategoryModal] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const handleOpenModal = (modal, detailItem, detailSubItem) => {
    if (modal === "modalAdd") {
      setShowModalAdd(true);
    } else if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
      console.log(detailItem);
    } else if (modal === "modalAddSubCategory") {
      setDetailItem(detailItem);
      setShowAddSubCategoryModal(true);
    } else if (modal === "modalUpdate") {
      setDetailItem(detailItem);
      setShowModalUpdate(true);
    } else if (modal === "modalDelete") {
      setDetailItem(detailItem);
      setShowModalDelete(true);
    } else if (modal === "modalUpdateSubCategory") {
      setDetailItem(detailItem);
      setDetailSubItem(detailSubItem);
      setShowUpdateSubCategoryModal(true);
    } else if (modal === "modalDeleteSubCategory") {
      setDetailItem(detailItem);
      setDetailSubItem(detailSubItem);
      setShowDeleteSubCategoryModal(true);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalAdd") {
      setShowModalAdd(false);
    } else if (modal === "modalOperations") {
      setShowModalOperations(false);
    } else if (modal === "modalDelete") {
      setShowModalDelete(false);
    } else if (modal === "modalAddSubCategory") {
      setDetailItem(detailItem);
      setShowAddSubCategoryModal(false);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(false);
    } else if (modal === "modalUpdateSubCategory") {
      setShowUpdateSubCategoryModal(false);
    } else if (modal === "modalDeleteSubCategory") {
      setShowDeleteSubCategoryModal(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchData();
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.title.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };
  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        console.log(itemsData);
        console.log(data);

        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "دسته کالایی با موفقیت ثبت شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };
  const handleDelete = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        console.log(itemsData);
        console.log(data);

        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "دسته کالایی با موفقیت حذف شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const handleAddNewSubCtegory = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "زیر دسته کالایی با موفقیت ثبت شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();
  };
  const handleUpdate = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "دسته کالایی با موفقیت ویرایش شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };
  const handleUpdateSubCtegory = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "زیر دسته کالایی با موفقیت ویرایش شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();
  };
  const handleDeletSubCtegory = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/category/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        toast.update(toastId, {
          render: "زیر دسته کالایی با موفقیت حذف شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List handleOpenModal={handleOpenModal} data={items} />
          )}
        </ListWrapper>
        <ModalAdd
          showAddModal={showModalAdd}
          handleCloseModal={handleCloseModal}
          handleAddNew={handleAddNew}
        ></ModalAdd>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
        ></ModalOperations>
        <ModalAddSubCategory
          showAddSubCategoryModal={showAddSubCategoryModal}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleAddNewSubCtegory={handleAddNewSubCtegory}
        ></ModalAddSubCategory>
        <ModalUpdate
          detailItem={detailItem}
          showUpdateModal={showModalUpdate}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
        ></ModalUpdate>
        <ModalUpdateSubCategory
          showUpdateSubCategoryModal={showUpdateSubCategoryModal}
          detailItem={detailItem}
          detailSubItem={detailSubItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleUpdateSubCtegory={handleUpdateSubCtegory}
        ></ModalUpdateSubCategory>
        <ModalDelete
          showModalDelete={showModalDelete}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        ></ModalDelete>
        <ModalDeleteSubCategory
          showDeleteSubCategoryModal={showDeleteSubCategoryModal}
          detailItem={detailItem}
          detailSubItem={detailSubItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleDeletSubCtegory={handleDeletSubCtegory}
        ></ModalDeleteSubCategory>
      </Container>
    </>
  );
};

export default Categories;
