import Joi from "joi";
import Mapir from "mapir-react-component";

import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  DivSelectUser,
  CoverImgUser,
  SelectUser,
  ImgUser,
  Lable,
  DivRow,
  DivColumn,
  DivMap,
  MapBox,
  Textarea,
  DivSelectTheme,
  Theme,
  DivTheme,
  BlurEffect,
  Checked,
} from "./Element";
import icon from "../../../Images/headerIcons/branch.svg";
import checkedIcon from "../../../Images/themes/checkedIcon.svg";
import orangeThemeImg from "../../../Images/themes/orange.jpg";
import greenThemeImg from "../../../Images/themes/green.jpg";
import blueThemeImg from "../../../Images/themes/blue.jpg";
import redThemeImg from "../../../Images/themes/red.jpg";
import iconUser from "../../../Images/usernameIcon.svg";
import { useTranslation } from "react-i18next";
// import AddRoleForm from "../../Form/AddRoleForm";
const Map = Mapir.setToken({
  transformRequest: (url) => {
    return {
      url: url,
      headers: {
        "x-api-key":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImMwMjU2NmQwMzRkOTRiYjQ3NjdlMzAwYzgyOGFjNDE4NWNjYjRiNjliZDdhNDYzNjQ4YTQ3ZDQ1NGZiOWViMDUzYzhiYTcwZDI2ZjhiODUzIn0.eyJhdWQiOiIxNjAwNSIsImp0aSI6ImMwMjU2NmQwMzRkOTRiYjQ3NjdlMzAwYzgyOGFjNDE4NWNjYjRiNjliZDdhNDYzNjQ4YTQ3ZDQ1NGZiOWViMDUzYzhiYTcwZDI2ZjhiODUzIiwiaWF0IjoxNjM2MjExNzkyLCJuYmYiOjE2MzYyMTE3OTIsImV4cCI6MTYzODcxNzM5Miwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.hVnSjrD1Ar4IbkpZPVEpjcEv8iCVtt2wImHj7bLkyxrtSFVjnCL1PhzIHbYVjQWNJx304IAxNfZWsnFIy0ea0sdUFNgmPDbEoOJ5y7b0oSvSIm9bDv2xJqutASGHRzHnEnNgVjZAkrcAbX1aAWQ9COg2w87iixpkHjkXBZsAMm66HkpR1rb3af-8unvhBRQUmaO-abmPh_SbUJ-SFx3LWzz10mAugAfK13wcC48N31Ry_pJOyJrv1BFezzkHHPiAFrdjsnPBobF81xdFMuP8Pd8L_k-Rv1iEARA_7usYZ6N0dGqtXE6k2gnGkT5iy_iKUAka3SdBRpqfI502MID-fQ", //Mapir api key
        "Mapir-SDK": "reactjs",
      },
    };
  },
});

const Update = ({
  showModalUpdateBranch,
  handleCloseModal,
  handleOpenModal,
  detailItem,
  detailSubItem,
  branchAdmin,
  handleUpdateBranch,
}) => {
  const [data, setData] = useState({
    title: "",
    slogan: "",
    trade_unit_id: null,
    user_id: null,
    theme: "orange",
    status: 1,
    latitude: null,
    longitude: null,
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalUpdateBranch);
    if (showModalUpdateBranch) {
      if (branchAdmin) {
        setUser(branchAdmin);
        setData({ ...data, user_id: branchAdmin.id });
      } else {
        setData({
          ...data,
          title: detailSubItem?.title,
          slogan: detailSubItem?.slogan,
          trade_unit_id: detailSubItem?.trade_unit_id,
          user_id: detailSubItem?.user?.id,
          theme: detailSubItem?.theme,
          status: 1,
          latitude: detailSubItem?.latitude,
          longitude: detailSubItem?.longitude,
          address: detailSubItem?.address,
        });
        setUser(detailSubItem?.user);
      }
    }
  }, [branchAdmin, showModalUpdateBranch, detailSubItem]);

  const schema = Joi.object({
    title: Joi.string().optional(),
    slogan: [Joi.string().optional(), Joi.allow(null)],
    trade_unit_id: [Joi.string().optional(), Joi.allow(null)],
    user_id: Joi.number().integer().required(),
    latitude: Joi.number().required(),
    longitude: Joi.number().required(),
    address: Joi.string().optional(),
    theme: Joi.string().optional(),
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const handleOpenModalSelectAdmin = () => {
    handleCloseModal("modalUpdateBranch");
    handleOpenModal("modalUpdateSelectBranchAdmin", detailItem, {
      ...data,
      user: detailSubItem?.user,
      id: detailSubItem?.id,
    });
  };
  const CloseModal = () => {
    handleOpenModal("modalOperations");
    handleCloseModal("modalUpdateBranch", detailItem);
    setUser(null);

    setErrors({});
  };

  const handleChange = (e) => {
    console.log(data);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const handleSelectTheme = (theme) => {
    console.log(theme);
    setData({ ...data, theme: theme });
    console.log(data.theme);
  };
  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      setDisabledBtn(true);
      doSubmit();
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    console.log(data.latitude);

    if (!result.error) return null;
    if (result.error.details[0].path[0] === "title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "user_id") {
      errorMessage = "لطفا مدیر شعبه را انتخاب کنید";
    } else if (result.error.details[0].path[0] === "latitude") {
      errorMessage = "لطفا آدرس را انتخاب کنید";
    } else if (result.error.details[0].path[0] === "address") {
      errorMessage = "آدرس نمی تواند خالی باشد";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("updating branch ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put("/branch/update/" + detailSubItem.id, data, config);
      isOkResponse = true;
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      console.log(errors);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      CloseModal();
      handleUpdateBranch(toastId);
    }
  };
  const [markerArray, setMarkerArray] = useState([]);
  function reverseFunction(map, e) {
    var url = `https://map.ir/reverse/no?lat=${e.lngLat.lat}&lon=${e.lngLat.lng}`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImMwMjU2NmQwMzRkOTRiYjQ3NjdlMzAwYzgyOGFjNDE4NWNjYjRiNjliZDdhNDYzNjQ4YTQ3ZDQ1NGZiOWViMDUzYzhiYTcwZDI2ZjhiODUzIn0.eyJhdWQiOiIxNjAwNSIsImp0aSI6ImMwMjU2NmQwMzRkOTRiYjQ3NjdlMzAwYzgyOGFjNDE4NWNjYjRiNjliZDdhNDYzNjQ4YTQ3ZDQ1NGZiOWViMDUzYzhiYTcwZDI2ZjhiODUzIiwiaWF0IjoxNjM2MjExNzkyLCJuYmYiOjE2MzYyMTE3OTIsImV4cCI6MTYzODcxNzM5Miwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.hVnSjrD1Ar4IbkpZPVEpjcEv8iCVtt2wImHj7bLkyxrtSFVjnCL1PhzIHbYVjQWNJx304IAxNfZWsnFIy0ea0sdUFNgmPDbEoOJ5y7b0oSvSIm9bDv2xJqutASGHRzHnEnNgVjZAkrcAbX1aAWQ9COg2w87iixpkHjkXBZsAMm66HkpR1rb3af-8unvhBRQUmaO-abmPh_SbUJ-SFx3LWzz10mAugAfK13wcC48N31Ry_pJOyJrv1BFezzkHHPiAFrdjsnPBobF81xdFMuP8Pd8L_k-Rv1iEARA_7usYZ6N0dGqtXE6k2gnGkT5iy_iKUAka3SdBRpqfI502MID-fQ",
      },
    })
      .then((response) => response.json())
      .then((detail) => {
        updateData(detail.address, e.lngLat.lat, e.lngLat.lng);
        console.log(detail);
      });
    const array = [];
    array.push(
      <Mapir.Marker
        coordinates={[e.lngLat.lng, e.lngLat.lat]}
        anchor="bottom"
      />
    );
    setMarkerArray(array);
    console.log(array);
  }
  const updateData = (newAddress, newLatitude, newLongitude) => {
    setData((prevData) => ({
      ...prevData,
      address: newAddress,
      latitude: newLatitude,
      longitude: newLongitude,
    }));
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("update branch")}</TitleHead>
          </Header>
          <Body>
            <DivRow>
              <DivInput>
                <Lable>{t("branch title")}</Lable>
                <Input id="title" onChange={handleChange} value={data.title} />
              </DivInput>
              <DivInput>
                <Lable>{t("branch slogan")}</Lable>
                <Input
                  id="slogan"
                  onChange={handleChange}
                  value={data.slogan}
                />
              </DivInput>
              <DivSelectUser>
                <CoverImgUser>
                  {user ? (
                    <ImgUser
                      haveAvatar={user.avatar ? true : false}
                      src={user.avatar ? user.avatar : iconUser}
                    />
                  ) : (
                    <ImgUser src={iconUser} />
                  )}
                </CoverImgUser>
                <SelectUser onClick={handleOpenModalSelectAdmin}>
                  {user
                    ? user.name + " " + user.family
                    : t("choose branch admin")}
                </SelectUser>
              </DivSelectUser>
            </DivRow>
            <DivRow>
              <DivMap>
                <Lable>{t("select address")}</Lable>
                <MapBox>
                  <Mapir
                    center={[detailSubItem?.longitude, detailSubItem?.latitude]}
                    Map={Map}
                    onClick={reverseFunction}
                  >
                    {markerArray}
                  </Mapir>
                </MapBox>
              </DivMap>
              <DivColumn>
                <DivInput>
                  <Lable>{t("select theme")}</Lable>
                  <DivSelectTheme>
                    <DivTheme onClick={() => handleSelectTheme("orange")}>
                      {data.theme === "orange" && (
                        <>
                          <BlurEffect />
                          <Checked src={checkedIcon} />
                        </>
                      )}
                      <Theme src={orangeThemeImg} />
                    </DivTheme>
                    <DivTheme onClick={() => handleSelectTheme("green")}>
                      {data.theme === "green" && (
                        <>
                          <BlurEffect />
                          <Checked src={checkedIcon} />
                        </>
                      )}
                      <Theme src={greenThemeImg} />
                    </DivTheme>
                    <DivTheme onClick={() => handleSelectTheme("blue")}>
                      {data.theme === "blue" && (
                        <>
                          <BlurEffect />
                          <Checked src={checkedIcon} />
                        </>
                      )}

                      <Theme src={blueThemeImg} />
                    </DivTheme>
                    <DivTheme onClick={() => handleSelectTheme("red")}>
                      {data.theme === "red" && (
                        <>
                          <BlurEffect />
                          <Checked src={checkedIcon} />
                        </>
                      )}
                      <Theme src={redThemeImg} />
                    </DivTheme>
                  </DivSelectTheme>
                </DivInput>
                <DivInput>
                  <Lable>{t("address")}</Lable>
                  <Textarea
                    id="address"
                    onChange={handleChange}
                    value={data.address}
                  />
                </DivInput>
                <BtnDiv btnPadding={true}>
                  <Btn okBtn onClick={handleSubmit}>
                    {t("edit")}
                  </Btn>
                  <Btn onClick={CloseModal}>{t("cancel")}</Btn>
                </BtnDiv>
              </DivColumn>
            </DivRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Update;
