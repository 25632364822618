import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5.6vh 3.3vw;
`;

// -- start Header -- //

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3vh;
`;
export const DivHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DivIconHead = styled.div`
  height: 3.2vw;
  width: 3.2vw;
  background: var(--orange);
  margin-inline-end: 0.6vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconHead = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  ${({ haveLogo }) => (haveLogo ? "" : "filter: brightness(0) invert(1);")}
`;
export const IconOperationHead = styled.img`
  height: 100%;
`;

export const TitleHead = styled.p`
  color: black;
  font-size: 0.94vw;
  font-weight: 700;
`;

// -- start Button -- //

export const Btn = styled.button`
  width: 46%;
  font-size: 0.9vw;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: flex-start;
  background: ${({ operation }) =>
    (operation === "EDIT" && "var(--orange)") ||
    (operation === "DELETE" && "var(--red)")};
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding-inline: 0.3vw;
  height: 3.2vw;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ operation }) =>
      (operation === "EDIT" && "var(--darkOrange)") ||
      (operation === "DELETE" && "var(--darkRed)")};
    color: white;
  }
`;
export const TitleBtn = styled.p`
  width: 3.8vw;
`;

export const BtnDiv = styled.div`
  width: 19.4vw;
  display: flex;
  flex-direction: ${({ rowReverse }) => (rowReverse ? "row-reverse" : "row")};
  align-items: flex-end;
  justify-content: space-between;
`;
export const BtnIcon = styled.img`
  width: 1.2vw;
  margin-left: 0.1vw;
  cursor: pointer;
  border-radius: 0.5vh;
  ${({ hover }) => hover && "&:hover {background-color: #3a7599;}"}
`;

// -- end Button -- //

// -- end Header -- //

// -- start Body -- //

export const Body = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  column-gap: 4.6vw;
  row-gap: 3vh;
  flex-direction: column;
`;
export const Detail = styled.div`
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  color: var(--lightBrown);
  height: 1.5vw;
  gap: 1.2vw;
`;
export const Description = styled.p`
  display: flex;
  font-size: 0.82vw;
  padding-inline-end: 1.3vw;
  color: var(--lightBrown);
  font-weight: 600;
`;

export const DivBranches = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.82vw;
  font-weight: 600;
  height: 100%;
  gap: 0.3vw;
`;
export const Branches = styled.p``;
export const DivAdmin = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.82vw;
  font-weight: 600;
  height: 100%;
  gap: 0.3vw;
`;
export const Admin = styled.p``;
export const IconDetail = styled.img`
  height: 100%;
`;

export const DivItems = styled.div`
  width: 60vw;
  display: flex;
  flex-direction: column;
`;
export const Lable = styled.label`
  color: var(--lable);
  font-size: 0.9vw;
  font-weight: 500;
  padding-bottom: 1vh;
  padding-inline-start: 0.5vw;
`;
export const DivList = styled.div`
  font-size: 0.9vw;
  height: 100%;
  width: 100%;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.8vw;
  overflow-x: hidden;
  &:-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ListItems = styled.div`
  position: relative;
  padding: 1vw;
  height: 49vh;
  width: 100%;
  border-radius: 1.6vh;
  border: 0.16vh dashed var(--dashedStroke);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CoverImgUser = styled.div`
  height: 6vh;
  width: 6vh;
  border: solid 0.1vw var(--inputStroke);
  background: var(--selectBackground);
  transition: all 0.2s ease-in-out;
  border-radius: 1.6vh;
`;

export const Item = styled.div`
  position: relative;
  width: 49.2%;
  display: flex;
  flex-direction: column;
  background: var(--branchBackground);
  padding: 0.9vw;
  color: white;
  font-size: 0.9vw;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  border-radius: 0.5vw;
  row-gap: 0.6vw;
  &:hover {
    background: var(--hoverBranchBackground);
  }
`;
export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: black;
`;

export const ImgCompany = styled.img`
  min-height: 3vw;
  max-height: 3vw;
  min-width: 3vw;
  max-width: 3vw;
  ${({ defualt }) => defualt && "background:white;"}
  border-radius: 50%;
`;
export const Title = styled.p`
  font-size: 0.9vw;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-inline-start: 1.2vw;
`;

export const Icon = styled.img`
  height: 100%;
  padding: 0.5vw;
  filter: brightness(0) invert(1);
`;
export const ItemDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: black;
  font-size: 0.82vw;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Price = styled.p`
  font-size: 1vw;
  font-weight: 900;
  padding-top: 0.7vw;
  color: var(--orange);
`;
export const Unit = styled.span`
  font-size: 0.8vw;
  font-weight: 700;
  padding-inline-start: 0.4vw;
  color: black;
`;

export const DivRate = styled.div`
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  background: var(--backgroundRate);
  border-radius: 0.5vw 0 0.5vw 0;
  padding: 0.5vw;
  display: flex;
  align-items: center;
`;
export const Rate = styled.p`
  color: black;
  font-size: 1vw;
  font-weight: 600;
  padding-top: 0.2vw;
`;
export const IconRate = styled.img`
  width: 1.6vw;
  padding-inline-end: 0.2vw;
`;

// -- end Body -- //
