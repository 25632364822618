import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

import Search from "../../Components/TradeUnions/Search";
import List from "../../Components/TradeUnions/List";
import {
  Container,
  ListWrapper,
  SearchWrapper,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import ModalAdd from "../../Components/TradeUnions/Modals/Add";
import ModalUpdate from "../../Components/TradeUnions/Modals/Update";
import ModalDelete from "../../Components/TradeUnions/Modals/Delete";

import ModalAddBranch from "../../Components/TradeUnions/Modals/AddBranch";
import ModalUpdateBranch from "../../Components/TradeUnions/Modals/UpdateBranch";
import ModalDeleteBranch from "../../Components/TradeUnions/Modals/DeleteBranch";

import ModalOperations from "../../Components/TradeUnions/Modals/Operations";

import ModalSelectAdmin from "../../Components/TradeUnions/Modals/SelectAdmin";
import ModalUpdateSelectAdmin from "../../Components/TradeUnions/Modals/UpdateSelectAdmin";
import ModalSelectBranchAdmin from "../../Components/TradeUnions/Modals/SelectBranchAdmin";
import ModalUpdateSelectBranchAdmin from "../../Components/TradeUnions/Modals/UpdateSelectBranchAdmin";

const TradeUnions = () => {
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [detailSubItem, setDetailSubItem] = useState([]);

  const [searchItems, setSearchItems] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [branchAdmin, setBranchAdmin] = useState(null);
  const [updateBranchAdmin, setUpdateBranchAdmin] = useState(null);

  const [showModalOperations, setShowModalOperations] = useState(false);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showModalSelectAdmin, setShowModalSelectAdmin] = useState(false);
  const [showModalUpdateSelectAdmin, setShowModalUpdateSelectAdmin] =
    useState(false);
  const [showModalAddBranch, setShowModalAddBranch] = useState(false);
  const [showModalUpdateBranch, setShowModalUpdateBranch] = useState(false);
  const [showModalDeleteBranch, setShowModalDeleteBranch] = useState(false);

  const [showModalSelectBranchAdmin, setShowModalSelectBranchAdmin] =
    useState(false);
  const [
    showModalUpdateSelectBranchAdmin,
    setShowModalUpdateSelectBranchAdmin,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleOpenModal = (modal, detailItem, detailSubItem) => {
    if (modal === "modalAdd") {
      setShowModalAdd(true);
    } else if (modal === "modalOperations") {
      setShowModalOperations(true);
      setDetailItem(detailItem);
    } else if (modal === "modalSelectAdmin") {
      setShowModalSelectAdmin(true);
    } else if (modal === "modalAddBranch") {
      setDetailItem(detailItem);
      setShowModalAddBranch(true);
    } else if (modal === "modalSelectBranchAdmin") {
      setDetailItem(detailItem);
      setShowModalSelectBranchAdmin(true);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(true);
      setDetailItem(detailItem);
    } else if (modal === "modalUpdateSelectAdmin") {
      setDetailItem(detailItem);
      setShowModalUpdateSelectAdmin(true);
    } else if (modal === "modalUpdateBranch") {
      setDetailItem(detailItem);
      setDetailSubItem(detailSubItem);
      setShowModalUpdateBranch(true);
    } else if (modal === "modalUpdateSelectBranchAdmin") {
      setDetailItem(detailItem);
      setDetailSubItem(detailSubItem);
      setShowModalUpdateSelectBranchAdmin(true);
    } else if (modal === "modalDelete") {
      setShowModalDelete(true);
    } else if (modal === "modalDeleteBranch") {
      setDetailItem(detailItem);
      setDetailSubItem(detailSubItem);
      setShowModalDeleteBranch(true);
    }
  };
  const handleCloseModal = (modal, detailItem) => {
    if (modal === "modalAdd") {
      setShowModalAdd(false);
      setAdmin(null);
    } else if (modal === "modalOperations") {
      setShowModalOperations(false);
    } else if (modal === "modalSelectAdmin") {
      setShowModalSelectAdmin(false);
    } else if (modal === "modalAddBranch") {
      setDetailItem(detailItem);
      setBranchAdmin(null);
      setShowModalAddBranch(false);
    } else if (modal === "modalSelectBranchAdmin") {
      setShowModalSelectBranchAdmin(false);
    } else if (modal === "modalUpdate") {
      setShowModalUpdate(false);
    } else if (modal === "modalUpdateSelectAdmin") {
      setShowModalUpdateSelectAdmin(false);
    } else if (modal === "modalUpdateBranch") {
      setDetailItem(detailItem);
      setShowModalUpdateBranch(false);
    } else if (modal === "modalUpdateSelectBranchAdmin") {
      setShowModalUpdateSelectBranchAdmin(false);
    } else if (modal === "modalDelete") {
      setShowModalDelete(false);
    } else if (modal === "modalDeleteBranch") {
      setDetailItem(detailItem);
      setShowModalDeleteBranch(false);
    }
  };
  const handleSelectAdmin = (admin) => {
    setAdmin(admin);
  };
  const handleSelectBranchAdmin = (admin) => {
    setBranchAdmin(admin);
  };
  const handleUpdateSelectBranchAdmin = (admin) => {
    setUpdateBranchAdmin(admin);
  };

  const handleResetUpdateModal = () => {
    setAdmin(null);
  };
  const handleResetUpdateBranchModal = () => {
    setUpdateBranchAdmin(null);
  };
  const handleAddNewBranch = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        console.log(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();

    toast.update(toastId, {
      render: "شعبه با موفقیت ثبت شد",
      type: "success",
      isLoading: false,
      autoClose: 3000,
      closeOnClick: true,
    });
  };
  const handleUpdateBranch = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        console.log(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();

    toast.update(toastId, {
      render: "شعبه با موفقیت ویرایش شد",
      type: "success",
      isLoading: false,
      autoClose: 3000,
      closeOnClick: true,
    });
  };
  const handleDeleteBranch = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        itemsData.forEach((element) => {
          if (element.id === detailItem.id) {
            setDetailItem(element);
          }
        });
        setItems(itemsData);
        setSearchItems(itemsData);
        console.log(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();

    toast.update(toastId, {
      render: "شعبه با موفقیت حذف شد",
      type: "success",
      isLoading: false,
      autoClose: 3000,
      closeOnClick: true,
    });
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchData();
  }, []);
  const handleAddNew = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "واحد صنفی با موفقیت ثبت شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };
  const handleDelete = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "واحد صنفی با موفقیت حذف شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };

  const handleUpdate = (toastId) => {
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/trade_unit/list", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
      toast.update(toastId, {
        render: "واحد صنفی با موفقیت ویرایش شد",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    fetchData();
  };

  const filter = (e) => {
    const keyword = e.target.value;
    console.log(keyword);
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        return item?.title.toLowerCase().includes(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(searchItems);
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container>
        <SearchWrapper>
          <Search filter={filter} />
        </SearchWrapper>
        <ListWrapper>
          {loading ? (
            <div className="loadingList">
              <div className="spinner"></div>
            </div>
          ) : (
            <List handleOpenModal={handleOpenModal} data={items} />
          )}
        </ListWrapper>
        <ModalAdd
          showAddModal={showModalAdd}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleAddNew={handleAddNew}
          admin={admin}
        ></ModalAdd>
        <ModalOperations
          showModalOperations={showModalOperations}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleResetUpdateModal={handleResetUpdateModal}
          handleResetUpdateBranchModal={handleResetUpdateBranchModal}
        ></ModalOperations>
        <ModalSelectAdmin
          showModalSelectAdmin={showModalSelectAdmin}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectAdmin={handleSelectAdmin}
        ></ModalSelectAdmin>
        <ModalAddBranch
          showModalAddBranch={showModalAddBranch}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          detailItem={detailItem}
          branchAdmin={branchAdmin}
          handleAddNewBranch={handleAddNewBranch}
        ></ModalAddBranch>
        <ModalSelectBranchAdmin
          showModalSelectBranchAdmin={showModalSelectBranchAdmin}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectBranchAdmin={handleSelectBranchAdmin}
          detailItem={detailItem}
        ></ModalSelectBranchAdmin>
        <ModalUpdate
          showUpdateModal={showModalUpdate}
          detailItem={detailItem}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
          admin={admin}
        ></ModalUpdate>
        <ModalDelete
          showModalDelete={showModalDelete}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        ></ModalDelete>

        <ModalUpdateSelectAdmin
          showModalUpdateSelectAdmin={showModalUpdateSelectAdmin}
          detailItem={detailItem}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectAdmin={handleSelectAdmin}
        ></ModalUpdateSelectAdmin>
        <ModalUpdateBranch
          showModalUpdateBranch={showModalUpdateBranch}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          detailItem={detailItem}
          detailSubItem={detailSubItem}
          branchAdmin={updateBranchAdmin}
          handleUpdateBranch={handleUpdateBranch}
        ></ModalUpdateBranch>
        <ModalUpdateSelectBranchAdmin
          showModalUpdateSelectBranchAdmin={showModalUpdateSelectBranchAdmin}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          handleSelectBranchAdmin={handleUpdateSelectBranchAdmin}
          detailItem={detailItem}
          detailSubItem={detailSubItem}
        ></ModalUpdateSelectBranchAdmin>
        <ModalDeleteBranch
          showModalDeleteBranch={showModalDeleteBranch}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          detailItem={detailItem}
          detailSubItem={detailSubItem}
          handleDeleteBranch={handleDeleteBranch}
        ></ModalDeleteBranch>
      </Container>
    </>
  );
};

export default TradeUnions;
