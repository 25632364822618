import React, { Component, useEffect, useState } from "react";
import List from "../../Components/Invoices/TradeUnionsRequests";
import StatusHistory from "../../Components/Invoices/Modals/StatusHistory";
import DetailItem from "../../Components/Invoices/TradeUnionRequest";
import {
  ContainerRequest,
  ContainerRequestsTradeUnions,
  ContainerTradeUnions,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
const Products = () => {
  const [items, setItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [itemDetails, setItemDetails] = useState(false);
  const [detailItem, setDetailItem] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [status, setStatus] = useState([]);
  const [filters, setFilters] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);

  const handleOpenModal = (modal) => {
    if (modal === "modalStatus") {
      setShowModalStatus(true);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalStatus") {
      setShowModalStatus(false);
    }
  };
  const token = localStorage.getItem("tokenPanelRoysa");
  const params = new URLSearchParams([
    ["per_page", 1000000],
    ["page", 1],
  ]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
    params,
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const { data } = await axiosConfig.get("/all_invoices", config);
        const itemsData = data.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setFilterItems(itemsData);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    async function fetchFilters() {
      try {
        const { data } = await axiosConfig.get("/status/list", config);
        const itemsData = data.data;
        setFilters(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }
    fetchData();
    fetchFilters();
  }, []);

  const handleSelectItem = (detailItem) => {
    setDetailItem(detailItem);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = searchItems.filter((item) => {
        console.log(item);
        return (
          item?.branch?.title.toLowerCase().includes(keyword.toLowerCase()) ||
          item?.branch?.trade_unit?.title
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          item?.number
            ?.toString()
            .toLowerCase()
            .startsWith(keyword.toLowerCase())
        );
      });
      setItems(results);
    } else {
      setItems(filterItems);
    }
  };
  const filterStatus = (status) => {
    setStatus(status);
    if (status !== 0) {
      let results = searchItems.filter((item) => {
        return item?.status?.id === status;
      });
      setItems(results);
      setFilterItems(results);
    } else {
      setItems(searchItems);
      setFilterItems(searchItems);
    }
  };
  return (
    <>
      <ContainerRequestsTradeUnions>
        {loading ? (
          <div className="loadingList">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <ContainerTradeUnions>
              <List
                handleSelectItem={handleSelectItem}
                data={items}
                filter={filter}
                filterStatus={filterStatus}
                filters={filters}
              />
              <StatusHistory
                showModalStatus={showModalStatus}
                handleCloseModal={handleCloseModal}
                itemDetails={detailItem}
              />
            </ContainerTradeUnions>
            <ContainerRequest>
              <DetailItem
                detailItem={detailItem}
                handleOpenModal={handleOpenModal}
              ></DetailItem>
            </ContainerRequest>
          </>
        )}
      </ContainerRequestsTradeUnions>
    </>
  );
};

export default Products;
