import {
  Container,
  Btn,
  DetailItem,
  Img,
  Header,
  DivIconHead,
  IconHead,
  TitleHead,
  Head,
  DivTime,
  CalendarTime,
  ClockTime,
  SeparatorTime,
  Body,
  DivDetail,
  Lable,
  DivDetailProduct,
  CoverImgProduct,
  ImgProduct,
  SeparatorDetailProduct,
  BtnDiv,
  DivDetailText,
  DetailText,
  Text,
  DivText,
  TitleBtn,
  BtnIcon,
  Footer,
  ImgDefault,
  DivEndHead,
  StatusDiv,
  CoverImgLogo,
  ImgLogo,
  DivRate,
  ImgStar,
  DivTextBody,
  TextBody,
} from "./ElementCompaniesRequests";
import icon from "../../Images/defaultAvatar.svg";
import acceptIcon from "../../Images/acceptIcon.svg";
import rejectIcon from "../../Images/rejectIcon.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import star from "../../Images/starIcon.svg";
import defaultBackground from "../../Images/defaultBackground.svg";

import Joi from "joi";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

const List = ({ detailItem, handleOpenModal, handleAccept }) => {
  const [item, setItem] = useState(null);
  const [data, setData] = useState({
    rate: null,
    body: "",
    status: 1,
  });
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (detailItem) {
      console.log(detailItem);
      setData({
        ...data,
        rate: detailItem?.rate,
        body: detailItem?.body,
        status: 1,
      });
      setItem(detailItem);
    }
  }, [detailItem]);
  const schema = Joi.object({
    rate: Joi.number().integer().required(),
    body: Joi.string().required(),
    status: Joi.number().integer().required(),
  });
  const getTime = (date) => {
    date = date.split(" ");
    return date[3];
  };
  const getCalendar = (date) => {
    date = date.split(" ");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return date[2] + "/" + date[1] + "/" + date[0];
  };
  const doAccept = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("accepting comment ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put("/comment/update/" + detailItem.id, data, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    if (isOkResponse) {
      handleAccept(toastId, data, detailItem.id);
    }
  };
  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container UnPaddingStart>
        <DetailItem>
          {!item ? (
            <ImgDefault src={defaultBackground} />
          ) : (
            <>
              <Header>
                <Head>
                  <DivIconHead
                    isLogo={item?.user?.avatar}
                    status={
                      (item?.status === "0" && "PENDING") ||
                      (item?.status === "1" && "AGREE") ||
                      (item?.status === "2" && "DENY")
                    }
                  >
                    <IconHead
                      isLogo={item?.user?.avatar}
                      src={item?.user?.avatar ? item?.user?.avatar : icon}
                    />
                  </DivIconHead>
                  <TitleHead>{item?.user?.full_name}</TitleHead>
                </Head>
                <DivEndHead
                  status={
                    (item?.status === "0" && "PENDING") ||
                    (item?.status === "1" && "AGREE") ||
                    (item?.status === "2" && "DENY")
                  }
                >
                  {((item?.status === "1" && "AGREE") ||
                    (item?.status === "2" && "DENY")) && (
                    <>
                      <StatusDiv
                        status={
                          (item?.status === "1" && "AGREE") ||
                          (item?.status === "2" && "DENY")
                        }
                      >
                        <Img
                          src={
                            (item?.status === "1" && agreeIcon) ||
                            (item?.status === "2" && denyIcon)
                          }
                        />
                      </StatusDiv>
                      {(item?.status === "1" && "تایید شده") ||
                        (item?.status === "2" && "رد شده")}
                    </>
                  )}

                  <DivTime
                    status={
                      (item?.status === "0" && "PENDING") ||
                      (item?.status === "1" && "AGREE") ||
                      (item?.status === "2" && "DENY")
                    }
                  >
                    <ClockTime>{getTime(item?.jalali_created_at)}</ClockTime>
                    <SeparatorTime
                      status={
                        (item?.status === "0" && "PENDING") ||
                        (item?.status === "1" && "AGREE") ||
                        (item?.status === "2" && "DENY")
                      }
                    />
                    <CalendarTime>
                      {getCalendar(item?.jalali_created_at)}
                    </CalendarTime>
                  </DivTime>
                </DivEndHead>
              </Header>
              <Body>
                <DivDetail>
                  <DivDetailProduct>
                    <DivDetailText>
                      <DivRate>
                        {item?.rate}
                        <ImgStar src={star} />
                      </DivRate>
                      <DivTextBody>
                        <TextBody>{item?.body}</TextBody>
                      </DivTextBody>
                    </DivDetailText>
                  </DivDetailProduct>
                </DivDetail>
                <DivDetail>
                  <Lable>جزئیات محصول</Lable>
                  <DivDetailProduct>
                    <DivDetailText>
                      <DetailText>
                        <CoverImgProduct>
                          <ImgProduct src={item?.product?.image} />
                        </CoverImgProduct>
                        <DivText>
                          <Lable> عنوان محصول :</Lable>
                          <Text>{item?.product?.title}</Text>
                        </DivText>
                      </DetailText>
                      <DetailText>
                        <SeparatorDetailProduct />
                        <CoverImgLogo>
                          <ImgLogo src={item?.company?.logo} />
                        </CoverImgLogo>
                        <DivText>
                          <Lable> نام شرکت پخش :</Lable>
                          <Text>{item?.company?.title}</Text>
                        </DivText>
                      </DetailText>
                    </DivDetailText>
                  </DivDetailProduct>
                </DivDetail>
              </Body>
              {item?.status === "0" && (
                <Footer>
                  <BtnDiv btnPadding={true}>
                    <Btn operation="ACCEPT" onClick={() => doAccept(item)}>
                      <BtnIcon src={acceptIcon} />
                      <TitleBtn>{t("accept")}</TitleBtn>
                    </Btn>
                    <Btn
                      operation="REJECT"
                      onClick={() => handleOpenModal("modalReject", item)}
                    >
                      <BtnIcon src={rejectIcon} />
                      <TitleBtn>{t("reject")}</TitleBtn>
                    </Btn>
                  </BtnDiv>
                </Footer>
              )}
            </>
          )}
        </DetailItem>
      </Container>
    </>
  );
};

export default List;
