import { Link } from "react-router-dom";
import styled from "styled-components";
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #feca31 0%, #f0b300 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const BoxDashboard = styled.div`
  width: 76vw;
  height: 84vh;
  display: flex;
  flex-direction: column;
  margin-top: -20vh;
`;
export const Header = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  padding-inline: 3vw;
  align-items: center;
`;
export const Profile = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DivProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const ProfileCover = styled.img`
  width: 8.8vw;
`;
export const ProfileImg = styled.img`
  ${({ defaultImg }) =>
    defaultImg
      ? "filter: brightness(0) invert(1);width: 63%;height: 63%;"
      : "width: 53%;height: 53%;"};
  border-radius: 50%;
  position: absolute;
`;

export const DivProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: var(--brown);
  margin-inline-start: 0.6vw;
`;
export const UserName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.1vw;
  font-weight: 800;
`;
export const UserPermission = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.9vw;
  font-weight: 600;
  margin-top: 0.7vh;
`;

export const LogoOrganization = styled.img`
  width: 7.6vw;
`;
export const BoxSelectItems = styled.div`
  width: 100%;
  height: 70%;
  padding: 5vh 3vw;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.4vw;
`;
export const DivSelectItems = styled.ul`
  width: 100%;
  height: 100%;
  border-radius: 1vw;
  display: flex;
  flex-wrap: wrap;
  row-gap: 3vh;
  column-gap: 3.2%;
  align-items: flex-start;
  list-style: none;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.li`
  display: flex;
  text-align: center;
  width: 14%;
  height: 22vh;
  border-radius: 1vw;
`;
export const ItemLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 1vw;
`;
export const ImgItem = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 1vw;
`;
export const ShadeItem = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
  color: white;
  font-size: 0.96vw;
  font-weight: 700;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(253, 181, 48, 0.7) 100%
  );
`;
export const IconItem = styled.img`
  width: 80%;
  margin-top: -2vh;
`;
export const Footer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
`;
export const DivFooterTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1vh;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  height: 1.2vh;
  padding-bottom: 0.2vh;
  padding-inline: 1.8vw;
  border-radius: 10vw;
`;
export const FooterTitle = styled.p`
  font-family: "Time New Roman" !important;
  font-weight: 600;
  font-size: 1.5vw;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  opacity: 76%;
`;
