import { Container, SearchBox, SearchIcon } from "../SearchInputElements";
import icon from "../../Images/search.svg";
import icon_ltr from "../../Images/search_ltr.svg";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const SearchPersonnels = ({ filter }) => {
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next");

  return (
    <>
      <Container>
        <SearchIcon
          src={currentLanguageCode === "fa" ? icon : icon_ltr}
        ></SearchIcon>
        <SearchBox
          placeholder={t("search tradeUnions ...")}
          onChange={filter}
        />
      </Container>
    </>
  );
};

export default SearchPersonnels;
