import styled from "styled-components";
export const Container = styled.div`
  height: 97%;
  width: 88vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 2.66%;
  row-gap: 2vh;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Item = styled.div`
  font-size: 0.8vw;
  width: 23%;
  position: relative;
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline-start: 2.3vw;
  top: 2.1vw;
  display: flex;
  row-gap: 1.3vw;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  height: 3.8vw;
  width: 3.8vw;
  border: solid 0.2vw var(--backgroundListWrapper);
  border-radius: 1vw;
`;
export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;

export const Title = styled.p`
  font-size: 1vw;
  font-weight: 700;
  padding-inline-end: 1.3vw;
`;
export const Permissions = styled.p`
  font-size: 0.9vw;
  font-weight: 400;
  padding-inline-end: 1.3vw;
`;
export const Count = styled.span`
  font-size: 1.1vw;
  font-weight: 900;
  padding-inline-end: 0.6vw;
  color: var(--orange);
`;

export const Btn = styled.button`
  position: absolute;
  inset-inline-end: 0.7vw;
  bottom: 1.2vw;
  height: 2.7vw;
  width: 2.7vw;
  border-radius: 1vw;
  border: none;
  margin-right: auto;
  background: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--lightBrown);
  }
`;

export const CoverIcon = styled.div`
  position: absolute;
  height: 3.2vw;
  width: 3.2vw;
  inset-inline-end: 1.8vw;
  top: 2.1vw;
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;

export const BtnAdd = styled.button`
  position: absolute;
  inset-inline-start: 1vw;
  bottom: 1.8vh;
  height: 2.8vw;
  border-radius: 1vw;
  border: none;
  background: var(--brown);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  font-weight: 600;
  font-size: 0.9vw;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
  }
`;
export const IconBtnAdd = styled.img`
  height: 100%;
`;
