import {
  Container,
  Item,
  Card,
  Btn,
  Icon,
  DetailItem,
  Img,
  CoverImg,
  Title,
  BtnAdd,
  IconBtnAdd,
  StatusColor,
} from "./ElementStatuses";
import card from "../../Images/cards/status.svg";
import card_ltr from "../../Images/cards/category_ltr.svg";
import setting from "../../Images/setting.svg";
import icon from "../../Images/addIcon.svg";
import defaultImg from "../../Images/headerIcons/categories.svg";
import iconStatus from "../../Images/headerIcons/status.svg";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const List = ({ data, handleOpenModal }) => {
  const [items, setItems] = useState([]);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <>
      <Container>
        {items.map((item) => (
          <Item>
            <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
            <DetailItem>
              <StatusColor color={item?.color}>
                <Img src={iconStatus} />
              </StatusColor>
              <Title>{item?.title}</Title>
            </DetailItem>
            <Btn onClick={() => handleOpenModal("modalOperations", item)}>
              <Icon src={setting} />
            </Btn>
          </Item>
        ))}
        <BtnAdd onClick={() => handleOpenModal("modalAdd")}>
          <IconBtnAdd src={icon} />
          {t("add status")}
        </BtnAdd>
      </Container>
    </>
  );
};

export default List;
