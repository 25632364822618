import { useEffect, useState } from "react";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";

import {
  Container,
  Header,
  TitleHead,
  Btn,
  BtnDiv,
  DivIconHead,
  DivHeader,
  TitleBtn,
  IconOperationHead,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/status.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";

import { useTranslation } from "react-i18next";
import { IconHead } from "./Element";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showModalOperations);
    setItem(detailItem);
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleUpdate = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete", detailItem);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead color={item?.color}>
                <IconHead src={icon} />
              </DivIconHead>
              <TitleHead>{item?.title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn onClick={handleUpdate} operation="EDIT">
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
