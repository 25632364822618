import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import "react-toastify/dist/ReactToastify.css";

import {
  Body,
  Container,
  Header,
  TitleHead,
  DivItems,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  ListItems,
  DivHeader,
  Lable,
  TitleBtn,
  Detail,
  DivBranches,
  Admin,
  Branches,
  DivAdmin,
  IconDetail,
  Item,
  DivList,
  IconOperationHead,
  Description,
  DivRate,
  IconRate,
  Rate,
  DivTitle,
  ImgCompany,
  Title,
  ItemDescription,
  Unit,
  Price,
} from "./OperationsElement";
import icon from "../../../Images/headerIcons/products.svg";
import editIcon from "../../../Images/editIcon.svg";
import deleteIcon from "../../../Images/deleteIcon.svg";
import categoryIcon from "../../../Images/categoryIcon.svg";
import measureUnitIcon from "../../../Images/measureUnitIcon.svg";
import starIcon from "../../../Images/starIcon.svg";
import defualtLogo from "../../../Images/headerIcons/deliveryCompanies.svg";
import { useTranslation } from "react-i18next";

const Operations = ({
  showModalOperations,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
  detailItem,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  async function fetchCompanies() {
    const token = localStorage.getItem("tokenPanelRoysa");
    const params = new URLSearchParams([
      ["per_page", 1000000],
      ["page", 1],
    ]);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      params,
    };

    try {
      const { data: categoriesData } = await axiosConfig.get(
        "/product/single/" + detailItem.id,
        config
      );
      setCompanies(categoriesData?.data?.details);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  }

  useEffect(() => {
    setShowModal(showModalOperations);
    if (showModalOperations) {
      setLoading(true);
      setItem(detailItem);
      fetchCompanies();
    }
  }, [detailItem, showModalOperations]);
  const CloseModal = () => {
    handleCloseModal("modalOperations");
  };
  const handleUpdate = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalUpdate", detailItem);
  };
  const handleDelete = () => {
    handleCloseModal("modalOperations");
    handleOpenModal("modalDelete");
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivHeader>
              <DivIconHead>
                <IconHead
                  haveLogo={item?.image ? true : false}
                  src={item?.image ? item?.image : icon}
                />
              </DivIconHead>
              <TitleHead>{item?.title}</TitleHead>
            </DivHeader>
            <BtnDiv btnPadding={true}>
              <Btn onClick={handleUpdate} operation="EDIT">
                <IconOperationHead src={editIcon} />
                <TitleBtn>{t("edit")}</TitleBtn>
              </Btn>
              <Btn operation="DELETE" onClick={handleDelete}>
                <IconOperationHead src={deleteIcon} />
                <TitleBtn>{t("delete")}</TitleBtn>
              </Btn>
            </BtnDiv>
          </Header>
          <Body>
            <Detail>
              <DivBranches>
                <IconDetail src={categoryIcon} />
                <Branches>
                  {item?.category?.parent?.title +
                    " _ " +
                    item?.category?.title}
                </Branches>
              </DivBranches>
              <DivAdmin>
                <IconDetail src={measureUnitIcon} />
                <Admin>{item?.measure_unit}</Admin>
              </DivAdmin>
            </Detail>
            <Description>{item?.description}</Description>
            <DivItems>
              <Lable>شرکت های پخش</Lable>
              <ListItems>
                {loading ? (
                  <div className="loadingList">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <DivList>
                    {companies.map((company) => (
                      <Item>
                        <DivTitle>
                          <ImgCompany
                            defualt={!company?.company?.logo}
                            src={
                              company?.company?.logo
                                ? company?.company?.logo
                                : defualtLogo
                            }
                          />
                          <Title>{company?.company?.title}</Title>
                        </DivTitle>
                        <ItemDescription>
                          {company?.description}
                        </ItemDescription>
                        <Price>
                          {numberWithCommas(company?.confirmed_price)}
                          <Unit>{t("unit")}</Unit>
                        </Price>
                        <DivRate>
                          <IconRate src={starIcon} />
                          <Rate>4.5</Rate>
                        </DivRate>
                      </Item>
                    ))}
                  </DivList>
                )}
              </ListItems>
            </DivItems>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Operations;
