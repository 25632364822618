import {
  Container,
  Item,
  Img,
  CoverImg,
  Title,
  Description,
  Div,
  Time,
  Detail,
  DivFilters,
  Btnfilter,
  DivInputSearch,
  SearchIcon,
  StatusDiv,
  DivItems,
} from "./ElementCompaniesRequests";
import defaultLogo from "../../Images/headerIcons/deliveryCompanies.svg";
import searchIcon from "../../Images/search.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputSearch } from "../DeliveryCompanies/Modals/Element";
const List = ({ data, filter, filterStatus, handleSelectItem }) => {
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(4);
  const [activeSlider, setActiveSlider] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const handleSelectTab = (status) => {
    filterStatus(status);
    setActiveTab(status);
  };
  const handleSelectComment = (item) => {
    handleSelectItem(item);
    setActiveSlider(item.id);
  };
  const handleDate = (date) => {
    date = date.split(" ");
    let time = date[3].split(":");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return (
      time[0] + ":" + time[1] + " | " + date[2] + "/" + date[1] + "/" + date[0]
    );
  };

  return (
    <>
      <Container>
        <DivFilters>
          <Btnfilter
            onClick={() => handleSelectTab(4)}
            active={activeTab === 4}
          >
            همه
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab("pending")}
            active={activeTab === "pending"}
          >
            در انتظار تایید
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab("agree")}
            active={activeTab === "agree"}
          >
            تایید شده ها
          </Btnfilter>
          <Btnfilter
            onClick={() => handleSelectTab("deny")}
            active={activeTab === "deny"}
          >
            رد شده ها
          </Btnfilter>
        </DivFilters>
        <DivInputSearch>
          <SearchIcon src={searchIcon} />
          <InputSearch
            placeholder="جستجو کنید ..."
            id="search"
            onChange={filter}
          />
        </DivInputSearch>
        <DivItems>
          {items.map((item) => (
            <Item
              active={item.id === activeSlider}
              onClick={() => handleSelectComment(item)}
            >
              <CoverImg
                isLogo={item?.company?.logo}
                status={
                  (item?.status === "pending" && "PENDING") ||
                  (item?.status === "agree" && "AGREE") ||
                  (item?.status === "deny" && "DENY")
                }
              >
                <Img
                  isLogo={item?.company?.logo}
                  src={item?.company?.logo ? item?.company?.logo : defaultLogo}
                />
              </CoverImg>
              <Detail>
                <Div>
                  <Title active={item.id === activeSlider}>
                    {item?.company?.title}
                  </Title>
                  <Time
                    status={
                      (item?.status === "pending" &&
                        item.id !== activeSlider &&
                        "PENDING") ||
                      (item?.status === "pending" &&
                        item.id === activeSlider &&
                        "ACTIVE_PENDING") ||
                      (item?.status === "agree" && "AGREE") ||
                      (item?.status === "deny" && "DENY")
                    }
                  >
                    {handleDate(item?.jalali_created_at)}
                    {item?.status !== "pending" && (
                      <StatusDiv
                        status={
                          (item?.status === "agree" && "AGREE") ||
                          (item?.status === "deny" && "DENY")
                        }
                      >
                        <Img
                          src={
                            (item?.status === "agree" && agreeIcon) ||
                            (item?.status === "deny" && denyIcon)
                          }
                        />
                      </StatusDiv>
                    )}
                  </Time>
                </Div>
                <Description active={item.id === activeSlider}>
                  {item?.body}
                </Description>
              </Detail>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default List;
