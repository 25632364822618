import React, { useEffect, useState } from "react";
import List from "../../Components/Comments/List";
import DetailItem from "../../Components/Comments/DetailComment";
import {
  ContainerCompanies,
  ContainerRequest,
  ContainerRequestsCompanies,
} from "../../Components/ContainerSoftware/ContainerElements";
import axiosConfig from "../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

import ModalReject from "../../Components/Comments/Modals/Reject";

const Comments = () => {
  const [items, setItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [status, setStatus] = useState([]);

  const [itemDetails, setItemDetails] = useState(false);
  const [detailItem, setDetailItem] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleOpenModal = (modal, itemDetails) => {
    if (modal === "modalReject") {
      setShowModalReject(true);
      setItemDetails(itemDetails);
    }
  };
  const handleCloseModal = (modal) => {
    if (modal === "modalReject") {
      setShowModalReject(false);
    }
  };
  const handleSelectItem = (detailItem) => {
    setDetailItem(detailItem);
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/comments", config);
        const itemsData = data?.data?.data;
        setItems(itemsData);
        setSearchItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchData();
  }, []);

  const handleAccept = (toastId, commentData, commentId) => {
    setDetailItem({
      ...detailItem,
      status: "1",
    });

    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/comments", config);
        const itemsData = data?.data?.data;
        console.log(commentId);
        searchItems.forEach((item) => {
          itemsData.filter((x) => x.id === item.id);
        });
        let filtereditemsData;
        if (status === "0") {
          filtereditemsData = itemsData.filter((item1) =>
            items.some(
              (item2) => item2.id === item1.id && item2.id !== commentId
            )
          );
        } else {
          filtereditemsData = itemsData.filter((item1) =>
            items.some((item2) => item2.id === item1.id)
          );
        }
        setSearchItems(itemsData);
        setItems(filtereditemsData);
        setFilterItems(filtereditemsData);
        toast.update(toastId, {
          render: "نظر با موفقیت تایید شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const handleRejectRequest = (toastId, requestData) => {
    setDetailItem({
      ...detailItem,
      status: "2",
    });

    async function fetchData() {
      const token = localStorage.getItem("tokenPanelRoysa");
      const params = new URLSearchParams([
        ["per_page", 1000000],
        ["page", 1],
      ]);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };

      try {
        const { data } = await axiosConfig.get("/comments", config);
        const itemsData = data?.data?.data;
        let filtereditemsData;
        if (status === "0") {
          filtereditemsData = itemsData.filter((item1) =>
            items.some(
              (item2) => item2.id === item1.id && item2.id !== detailItem.id
            )
          );
        } else {
          filtereditemsData = itemsData.filter((item1) =>
            items.some((item2) => item2.id === item1.id)
          );
        }
        setSearchItems(itemsData);
        setItems(filtereditemsData);
        setFilterItems(filtereditemsData);
        toast.update(toastId, {
          render: "نظر با موفقیت رد شد",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (error) {
        console.log("Error fetching ctegories:", error);
      }
    }
    fetchData();
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      let results = items.filter((item) => {
        let nameFamily = item?.user?.name + " " + item?.user?.family;
        return (
          nameFamily.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.user?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item?.user?.family.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setItems(results);
    } else {
      setItems(filterItems);
    }
  };
  const filterStatus = (status) => {
    setStatus(status);
    if (status !== 4) {
      let results = searchItems.filter((item) => {
        return item?.status === status;
      });
      setItems(results);
      setFilterItems(results);
    } else {
      setItems(searchItems);
      setFilterItems(searchItems);
    }
  };
  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ContainerRequestsCompanies>
        {loading ? (
          <div className="loadingList">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <ContainerCompanies>
              <List
                handleSelectItem={handleSelectItem}
                data={items}
                filter={filter}
                filterStatus={filterStatus}
              />
            </ContainerCompanies>
            <ContainerRequest>
              <DetailItem
                detailItem={detailItem}
                handleOpenModal={handleOpenModal}
                handleAccept={handleAccept}
              ></DetailItem>
            </ContainerRequest>
          </>
        )}
      </ContainerRequestsCompanies>
      <ModalReject
        itemDetails={itemDetails}
        showModalReject={showModalReject}
        handleCloseModal={handleCloseModal}
        handleRejectRequest={handleRejectRequest}
      ></ModalReject>
    </>
  );
};

export default Comments;
